import type { SurvivingFieldValueRule } from 'app/dop/types/survivingFieldValueTypes';
import type { ConnectionTableField } from 'app/settings/types/connectionTableFieldTypes';

const FieldMapping: Record<string, [string, string][]> = {
	'Lead-Account': [
		['AnnualRevenue', 'AnnualRevenue'],
		['City', 'BillingCity'],
		['Company', 'Name'],
		['Country', 'BillingCountry'],
		['Description', 'Description'],
		['CompanyDunsNumber', 'DunsNumber'],
		['Fax', 'Fax'],
		['Industry', 'Industry'],
		['Latitude', 'BillingLatitude'],
		['Longitude', 'BillingLongitude'],
		['NumberOfEmployees', 'NumberOfEmployees'],
		['Phone', 'Phone'],
		['PostalCode', 'BillingPostalCode'],
		['Rating', 'Rating'],
		['State', 'BillingState'],
		['Street', 'BillingStreet'],
		['Website', 'Website'],
	],
	'Lead-Contact': [
		['Company', 'AccountId.Name'],
		['City', 'MailingCity'],
		['Country', 'MailingCountry'],
		['Description', 'Description'],
		['Email', 'Email'],
		['Fax', 'Fax'],
		['FirstName', 'FirstName'],
		['LastName', 'LastName'],
		['Latitude', 'MailingLatitude'],
		['Longitude', 'MailingLongitude'],
		['LeadSource', 'LeadSource'],
		['MobilePhone', 'MobilePhone'],
		['Name', 'Name'],
		['Phone', 'Phone'],
		['PostalCode', 'MailingPostalCode'],
		['Salutation', 'Salutation'],
		['State', 'MailingState'],
		['Street', 'MailingStreet'],
		['Title', 'Title'],
	],
	'Contact-Account': [
		['AccountId', 'Id'],
		['AccountId.Name', 'Name'],
		['MailingCity', 'BillingCity'],
		['MailingCountry', 'BillingCountry'],
		['Description', 'Description'],
		['Fax', 'Fax'],
		['MailingLatitude', 'BillingLatitude'],
		['MailingLongitude', 'BillingLongitude'],
		['Phone', 'Phone'],
		['MailingPostalCode', 'BillingPostalCode'],
		['MailingState', 'BillingState'],
		['MailingStreet', 'BillingStreet'],
	],
};

class SalesforceFieldMapping {
	sourceObject: string;

	targetObject: string;

	mapping: [string | null, string | null, string, ConnectionTableField][];

	constructor(
		sourceObject: string,
		targetObject: string,
		fields: ConnectionTableField[],
		targetFields: ConnectionTableField[],
		survivingFieldValueRules: SurvivingFieldValueRule[] = []
	) {
		if (
			sourceObject !== targetObject &&
			(!['Lead', 'Account', 'Contact'].includes(sourceObject) || !['Account', 'Contact'].includes(targetObject))
		) {
			throw new Error(`Flow ${sourceObject} -> ${targetObject} not supported`);
		}

		this.sourceObject = sourceObject;
		this.targetObject = targetObject;

		this.mapping = [] as [string | null, string | null, string, ConnectionTableField][];

		this.runMapping(fields, targetFields, survivingFieldValueRules);
	}

	runMapping(
		fields: ConnectionTableField[],
		targetFields: ConnectionTableField[],
		survivingFieldValueRules: SurvivingFieldValueRule[]
	) {
		FieldMapping[`${this.sourceObject}-${this.targetObject}`].forEach(m => {
			const sourceField = fields.find(f => f.name === m[0]);
			const targetField = targetFields.find(f => f.name === m[1]);

			if (sourceField && targetField) {
				this.mapping.push([m[0], m[1], 'CRM', sourceField]);
			}
		});

		survivingFieldValueRules.forEach(rule => {
			const sourceField = fields.find(f => f.name === rule.target_field);
			const targetField = targetFields.find(f => f.name === rule.field);

			if (sourceField && targetField) {
				this.mapping.push([sourceField.name, targetField.name, 'By Rule', sourceField]);
			}
		});

		const UndefinedSourceFields = [] as [string | null, string | null, string, ConnectionTableField][];

		fields.forEach(field => {
			const isStandard = this.mapping.find(m => m[0] === field.name);

			if (isStandard) {
				return;
			}

			let targetField = field.name !== 'Id' ? targetFields.find(f => f.name === field.name) : null;

			if (
				this.targetObject === 'Account' &&
				targetField &&
				targetField.label.includes('Company') &&
				!field.label.includes('Company')
			) {
				targetField = null;
			} else if (this.targetObject === 'Account' && !targetField && field.label.includes('Company')) {
				targetField =
					field.name !== 'Id'
						? targetFields.find(f => field.label.toLowerCase() === f.label.toLowerCase())
						: null;
			}

			if (!targetField || this.mapping.find(m => m[1] === targetField.name)) {
				UndefinedSourceFields.push([field.name, null, 'Undefined', field]);
				return;
			}

			this.mapping.push([field.name, targetField.name, 'By Field Name', field]);
		});

		this.mapping = [...this.mapping, ...UndefinedSourceFields];
		const UndefinedTargetFields = [] as [string | null, string | null, string, ConnectionTableField][];

		targetFields.forEach(field => {
			if (this.mapping.find(m => m[1] === field.name)) {
				return;
			}

			UndefinedTargetFields.push([null, field.name, 'Undefined', field]);
		});

		this.mapping = [...this.mapping, ...UndefinedTargetFields];
	}
}

export default SalesforceFieldMapping;
