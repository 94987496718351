import { hasFeatureAccess } from '../admin/helpers/AccessHelper';
import type { User } from '../auth/types/user';

import { ConnectionTypes } from './constants/ConnectionConstants';
import type { Connection, ConnectionType } from './types/connectionTypes';

export function resolveConnectionTypeByUrl(url: string) {
	if (url.includes('salesforce')) {
		return ConnectionTypes.SALESFORCE;
	}

	if (url.includes('marketo')) {
		return ConnectionTypes.MARKETO;
	}

	if (url.includes('pardot')) {
		return ConnectionTypes.PARDOT;
	}

	if (url.includes('eloqua')) {
		return ConnectionTypes.ELOQUA;
	}

	if (url.includes('hubspot')) {
		return ConnectionTypes.HUBSPOT;
	}

	if (url.includes('dynamics')) {
		return ConnectionTypes.DYNAMICS;
	}

	if (url.includes('snowflake')) {
		return ConnectionTypes.SNOWFLAKE;
	}

	return null;
}

export function filterConnectionList(connectionTypes: ConnectionType[], user: User) {
	return connectionTypes.filter(connectionType =>
		hasFeatureAccess(
			user,
			[`${connectionType.toUpperCase()}_INTEGRATION`],
			`read:${connectionType.toLowerCase()}_connection`
		)
	);
}

export const isConnectionTypeSF = (connection: Connection) => connection.connection_type === ConnectionTypes.SALESFORCE;

export const isConnectionTypeMarketo = (connection: Connection) =>
	connection.connection_type === ConnectionTypes.MARKETO;

export const isConnectionTypeEloqua = (connection: Connection) => connection.connection_type === ConnectionTypes.ELOQUA;

export const isConnectionTypePardot = (connection: Connection) => connection.connection_type === ConnectionTypes.PARDOT;

export const isConnectionTypeHubSpot = (connection: Connection) =>
	connection.connection_type === ConnectionTypes.HUBSPOT;

export const isConnectionTypeDynamics = (connection: Connection) =>
	connection.connection_type === ConnectionTypes.DYNAMICS;

export const isConnectionTypeSnowflake = (connection: Connection) =>
	connection.connection_type === ConnectionTypes.SNOWFLAKE;
