import concat from 'lodash-es/concat';
import map from 'lodash-es/map';
import reject from 'lodash-es/reject';
import { LOCATION_CHANGE } from 'redux-first-history';

import { RoutingTimeOffConstants } from '../Constants';

const initialState = {
	fetching: true,
	editItem: null,
	timeOffs: [],
	errors: null,
};

export default (state = initialState, action = {}) => {
	switch (action.type) {
		case LOCATION_CHANGE:
			return initialState;

		case RoutingTimeOffConstants.ROUTING_TIME_OFF_LIST_REQUEST:
			return { ...state, fetching: true };

		case RoutingTimeOffConstants.ROUTING_TIME_OFF_LIST_SUCCESS:
			return { ...state, timeOffs: action.data, fetching: false };

		case RoutingTimeOffConstants.ROUTING_TIME_OFF_LIST_FAIL:
			return { ...state, fetching: false };

		case RoutingTimeOffConstants.ROUTING_TIME_OFF_UPDATE_OK:
			return {
				...state,
				timeOffs: map(state.timeOffs, timeOff =>
					timeOff.id === action.data.id ? { ...timeOff, ...action.data } : timeOff
				),
				errors: null,
			};

		case RoutingTimeOffConstants.ROUTING_TIME_OFF_UPDATE_FAIL:
			return { ...state, fetching: false, errors: action.data };

		case RoutingTimeOffConstants.ROUTING_TIME_OFF_CREATE_SUCCESS:
			return {
				...state,
				timeOffs: concat([], state.timeOffs, action.data),
				errors: null,
			};

		case RoutingTimeOffConstants.ROUTING_TIME_OFF_CREATE_FAIL:
			return { ...state, fetching: false, errors: action.data };

		case RoutingTimeOffConstants.ROUTING_TIME_OFF_DELETE_SUCCESS:
			return { ...state, timeOffs: reject(state.timeOffs, { id: action.id }) };

		case RoutingTimeOffConstants.ROUTING_TIME_OFF_DELETE_FAIL:
			return { ...state, fetching: false };

		case RoutingTimeOffConstants.ROUTING_TIME_OFF_EDIT_ITEM:
			return { ...state, editItem: action.data, errors: null };

		default:
			return state;
	}
};
