import { EventsConstants } from 'app/dop/Constants';

const initialState = {
	fetchingConditions: {},
	conditions: {},
	fetchingEnrichConditions: {},
	enrichConditions: {},
	fetchingChangedFieldConditions: {},
	changedFieldConditions: {},
};

const getArray = isEnrich => (isEnrich ? 'enrichConditions' : 'conditions');

export default (state = initialState, action = {}) => {
	switch (action.type) {
		case EventsConstants.GET_EVENT_CONDITIONS:
			return {
				...state,
				fetchingConditions: {
					...state.fetchingConditions,
					[action.eventId]: true,
				},
			};

		case EventsConstants.GET_EVENT_CONDITIONS_SUCCESS:
			return {
				...state,
				fetchingConditions: {
					...state.fetchingConditions,
					[action.eventId]: false,
				},
				conditions: {
					...state.conditions,
					[action.eventId]: action.data,
				},
			};

		case EventsConstants.GET_EVENT_CONDITIONS_FAIL:
			return {
				...state,
				fetchingConditions: {
					...state.fetchingConditions,
					[action.eventId]: false,
				},
				conditions: {
					...state.conditions,
					[action.eventId]: [],
				},
			};

		case EventsConstants.GET_EVENT_ENRICH_CONDITIONS:
			return {
				...state,
				fetchingEnrichConditions: {
					...state.fetchingEnrichConditions,
					[action.eventId]: true,
				},
			};

		case EventsConstants.GET_EVENT_ENRICH_CONDITIONS_SUCCESS:
			return {
				...state,
				fetchingEnrichConditions: {
					...state.fetchingEnrichConditions,
					[action.eventId]: false,
				},
				enrichConditions: {
					...state.enrichConditions,
					[action.eventId]: action.data,
				},
			};

		case EventsConstants.GET_EVENT_ENRICH_CONDITIONS_FAIL:
			return {
				...state,
				fetchingEnrichConditions: {
					...state.fetchingEnrichConditions,
					[action.eventId]: false,
				},
				enrichConditions: {
					...state.enrichConditions,
					[action.eventId]: [],
				},
			};

		case EventsConstants.GET_EVENT_CHANGED_FIELD_CONDITIONS:
			return {
				...state,
				fetchingChangedFieldConditions: {
					...state.fetchingEnrichConditions,
					[action.eventId]: true,
				},
			};

		case EventsConstants.GET_EVENT_CHANGED_FIELD_CONDITIONS_SUCCESS:
			return {
				...state,
				fetchingChangedFieldConditions: {
					...state.fetchingEnrichConditions,
					[action.eventId]: false,
				},
				changedFieldConditions: {
					...state.enrichConditions,
					[action.eventId]: action.data,
				},
			};

		case EventsConstants.GET_EVENT_CHANGED_FIELD_CONDITIONS_FAIL:
			return {
				...state,
				fetchingChangedFieldConditions: {
					...state.fetchingEnrichConditions,
					[action.eventId]: false,
				},
				changedFieldConditions: {
					...state.enrichConditions,
					[action.eventId]: [],
				},
			};

		case EventsConstants.CREATE_TMP_CONDITION: {
			const arr = getArray(action.data.is_enrich);
			return {
				...state,
				[arr]: {
					...state[arr],
					[action.eventId]: [...(state[arr][action.eventId] || []), action.data],
				},
			};
		}

		case EventsConstants.CREATE_CONDITION_SUCCESS: {
			const arr = getArray(action.data.is_enrich);
			return {
				...state,
				[arr]: {
					...state[arr],
					[action.eventId]: [
						...state[arr][action.eventId].filter(condition => condition.tmpId !== action.tmpId),
						action.data,
					],
				},
			};
		}

		case EventsConstants.CREATE_CHANGED_FIELD_CONDITION_SUCCESS: {
			return {
				...state,
				changedFieldConditions: {
					...state.changedFieldConditions,
					[action.eventId]: [...state.changedFieldConditions[action.eventId], action.data],
				},
			};
		}

		case EventsConstants.UPDATE_CONDITIONS_SUCCESS:
		case EventsConstants.UPDATE_TMP_CONDITIONS: {
			const arr = getArray(action.isEnrich);
			return {
				...state,
				[arr]: {
					...state[arr],
					[action.eventId]: state[arr][action.eventId].map(ff => {
						const ffId = ff.id || ff.tmpId;
						const actionCondition = action.data.find(f => {
							const fId = f.id || f.tmpId;
							return ffId === fId;
						});
						return actionCondition ? { ...ff, ...actionCondition } : ff;
					}),
				},
			};
		}

		case EventsConstants.UPDATE_CHANGED_FIELD_CONDITIONS_SUCCESS: {
			return {
				...state,
				changedFieldConditions: {
					...state.changedFieldConditions,
					[action.eventId]: state.changedFieldConditions[action.eventId].map(ff => {
						const actionCondition = action.data.find(f => f.id === ff.id);
						return actionCondition ? { ...ff, ...actionCondition } : ff;
					}),
				},
			};
		}

		case EventsConstants.REMOVE_CONDITION_SUCCESS:
		case EventsConstants.REMOVE_TMP_CONDITION: {
			const arr = getArray(action.isEnrich);
			return {
				...state,
				[arr]: {
					...state[arr],
					[action.eventId]: state[arr][action.eventId]?.filter(
						condition => condition.id !== action.id && condition.tmpId !== action.id
					),
				},
			};
		}

		case EventsConstants.REMOVE_CHANGED_FIELD_CONDITION_SUCCESS: {
			return {
				...state,
				changedFieldConditions: {
					...state.changedFieldConditions,
					[action.eventId]: state.changedFieldConditions[action.eventId]?.filter(
						condition => condition.id !== action.id
					),
				},
			};
		}

		case EventsConstants.REMOVE_EVENT_CONDITIONS_SUCCESS:
			const newConditions = { ...state.conditions };
			delete newConditions[action.eventId];
			const newEnrichConditions = { ...state.enrichConditions };
			delete newEnrichConditions[action.eventId];

			return {
				...state,
				conditions: newConditions,
				enrichConditions: newEnrichConditions,
			};

		default:
			return state;
	}
};
