import { EventsConstants } from 'app/dop/Constants';

const initialState = {
	discoverTasks: [],
	fetchingDiscoverTasks: true,
	fetchingRelatedResults: false,
	relatedResults: [],
	fetchingRelatedDescription: false,
	relatedDescription: {},
};

export default (state = initialState, action = {}) => {
	switch (action.type) {
		case EventsConstants.GET_EVENT_DISCOVERY_TASKS:
			return { ...state, fetchingDiscoverTasks: true, discoverTasks: [] };

		case EventsConstants.GET_EVENT_DISCOVERY_TASKS_SUCCESS:
			return { ...state, fetchingDiscoverTasks: false, discoverTasks: action.data };

		case EventsConstants.GET_EVENT_DISCOVERY_TASKS_FAIL:
			return { ...state, fetchingDiscoverTasks: false, discoverTasks: null };

		case EventsConstants.GET_EVENT_DISCOVERY_RELATED_SEARCH_RESULT:
			return { ...state, fetchingRelated: true, relatedResults: [] };

		case EventsConstants.GET_EVENT_DISCOVERY_RELATED_SEARCH_RESULT_SUCCESS:
			return { ...state, fetchingRelated: false, relatedResults: { ...action.data } };

		case EventsConstants.GET_EVENT_DISCOVERY_RELATED_SEARCH_RESULT_FAIL:
			return { ...state, fetchingRelated: false, relatedResults: [], error: action.data };

		case EventsConstants.GET_EVENT_DISCOVERY_RELATED_DESCRIBE:
			return { ...state, fetchingRelatedDescription: true, relatedDescription: {}, relatedResults: [] };

		case EventsConstants.GET_EVENT_DISCOVERY_RELATED_DESCRIBE_SUCCESS:
			return { ...state, fetchingRelatedDescription: false, relatedDescription: action.data };

		case EventsConstants.GET_EVENT_DISCOVERY_RELATED_DESCRIBE_FAIL:
			return { ...state, fetchingRelatedDescription: false, relatedDescription: {}, error: action.data };

		default:
			return state;
	}
};
