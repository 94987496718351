import { type ComponentType } from 'react';
import AssessmentOutlinedIcon from '@mui/icons-material/AssessmentOutlined';
import DirectionsOutlinedIcon from '@mui/icons-material/DirectionsOutlined';
import GrainOutlinedIcon from '@mui/icons-material/GrainOutlined';
import GroupWorkOutlinedIcon from '@mui/icons-material/GroupWorkOutlined';
import PagesIcon from '@mui/icons-material/Pages';
import SpaIcon from '@mui/icons-material/SpaOutlined';
import { blue, deepPurple, grey, purple } from '@mui/material/colors';
import type { SvgIconProps } from '@mui/material/SvgIcon';

export const TPL_TYPE_DM = 'DM';
export const TPL_TYPE_SV = 'SV';
export const TPL_TYPE_SR = 'SR';
export const TPL_TYPE_PPF = 'PPF';
export const TPL_TYPE_SGT = 'SGT';
export const TPL_TYPE_SGT_2 = 'SGT_2';
export const TPL_TYPE_RT = 'RT';
export const TPL_TYPE_PKG = 'PKG';
export const TPL_TYPE_PKG_FIELD = 'PKG_FIELD';
export const TPL_TYPE_PKG_FIELDS = 'PKG_FIELDS';
export const TPL_TYPE_NR = 'NR';
export const TPL_TYPE_EVNT = 'EVNT';

export const TemplateTypes: Record<
	string,
	{
		title: string;
		subheader: string;
		Icon: ComponentType<SvgIconProps>;
		backgroundColor: string;
		learnMore: string;
		comingSoon?: boolean;
	}
> = {
	[TPL_TYPE_DM]: {
		title: 'Matching Rules',
		subheader: 'AND/OR conditional logic with any SF fields',
		Icon: PagesIcon,
		backgroundColor: grey[600],
		learnMore: 'https://help.zoominfo.com/s/article/RingLead-Matching-Tab-Explained',
	},
	[TPL_TYPE_SV]: {
		title: 'Surviving Field Value Rules',
		subheader: 'Retain ALL valuable information',
		Icon: PagesIcon,
		backgroundColor: grey[600],
		learnMore: 'https://help.zoominfo.com/s/article/Overview-of-Salesforce-Surviving-Field-Values-Rules',
	},
	[TPL_TYPE_SR]: {
		title: 'Master Record Rules',
		subheader: 'Be confident your master stays intact',
		Icon: PagesIcon,
		backgroundColor: grey[600],
		learnMore: 'https://help.zoominfo.com/s/article/RingLead-Matching-Tab-Explained',
	},
	[TPL_TYPE_PPF]: {
		title: 'Group Filters',
		subheader: 'Only merge specific groups of duplicates',
		Icon: GroupWorkOutlinedIcon,
		backgroundColor: purple[500],
		learnMore: 'https://help.zoominfo.com/s/article/RingLead-Group-Filters-Explained',
	},
	[TPL_TYPE_SGT]: {
		title: 'Segment Mapping',
		subheader: 'Configure Segments to use anywhere in RingLead',
		Icon: GrainOutlinedIcon,
		backgroundColor: 'primary.main',
		learnMore: 'https://help.zoominfo.com/lightning/articles/help/How-to-Set-Up-a-Segmentation',
	},
	[TPL_TYPE_SGT_2]: {
		title: 'Segmentations',
		subheader: 'Easily configure segments of any data in Salesforce',
		Icon: GrainOutlinedIcon,
		backgroundColor: 'primary.main',
		learnMore: 'https://help.zoominfo.com/lightning/articles/help/How-to-Set-Up-a-Segmentation',
	},
	[TPL_TYPE_PKG_FIELDS]: {
		title: 'Package Manage',
		subheader: 'Create Enrich packages from your connected vendors',
		Icon: SpaIcon,
		backgroundColor: deepPurple[500],
		learnMore: 'https://help.zoominfo.com/lightning/articles/help/Package-Manage-Explained',
	},
	[TPL_TYPE_NR]: {
		title: 'Normalization',
		subheader: 'Standardize fields to conform to your business requirements',
		Icon: AssessmentOutlinedIcon,
		backgroundColor: blue[500],
		learnMore: 'https://help.zoominfo.com/s/article/Overview-of-Normalization-Rules-in-RingLead',
	},
	[TPL_TYPE_EVNT]: {
		title: 'Events',
		subheader: 'Track changes in your data and take action on those changes.',
		Icon: SpaIcon,
		backgroundColor: deepPurple[500],
		learnMore: 'https://help.zoominfo.com/s/article/How-to-Use-Events-in-RingLead',
	},
	[TPL_TYPE_RT]: {
		title: 'Routing',
		subheader: 'Perfectly assign owners to the right person or company',
		Icon: DirectionsOutlinedIcon,
		backgroundColor: 'secondary.main',
		learnMore: 'https://help.zoominfo.com/lightning/articles/help/Overview-of-RingLead-Route',
		comingSoon: true,
	},
};
