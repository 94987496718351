import { LOCATION_CHANGE } from 'redux-first-history';

import { DiscoveryConstants } from '../Constants';

const initialState = {
	fetchingSnowflakeDatabases: false,
	snowflakeDatabases: [],
	fetchingSnowflakeTables: false,
	snowflakeTables: [],
	fetchingSnowflakeColumns: false,
	snowflakeColumns: [],
	snowflakeMappings: [],
	snowflakeWarehouses: [],
	fetchingSnowflakeWarehouses: false,
};

export default (state = initialState, action = {}) => {
	switch (action.type) {
		case LOCATION_CHANGE:
			return initialState;

		case DiscoveryConstants.DISCOVERY_GET_SNOWFLAKE_DATABASES:
			return { ...state, fetchingSnowflakeDatabases: true };

		case DiscoveryConstants.DISCOVERY_GET_SNOWFLAKE_DATABASES_SUCCESS:
			return { ...state, snowflakeDatabases: action.data, fetchingSnowflakeDatabases: false };

		case DiscoveryConstants.DISCOVERY_GET_SNOWFLAKE_DATABASES_FAIL:
			return { ...state, snowflakeDatabases: [], fetchingSnowflakeDatabases: false };

		case DiscoveryConstants.DISCOVERY_GET_SNOWFLAKE_TABLES:
			return { ...state, snowflakeTables: [], fetchingSnowflakeTables: true };

		case DiscoveryConstants.DISCOVERY_GET_SNOWFLAKE_TABLES_SUCCESS:
			return { ...state, snowflakeTables: action.data, fetchingSnowflakeTables: false };

		case DiscoveryConstants.DISCOVERY_GET_SNOWFLAKE_TABLES_FAIL:
			return { ...state, snowflakeTables: [], fetchingSnowflakeTables: false };

		case DiscoveryConstants.DISCOVERY_GET_SNOWFLAKE_WAREHOUSES:
			return { ...state, fetchingSnowflakeWarehouses: true };

		case DiscoveryConstants.DISCOVERY_GET_SNOWFLAKE_WAREHOUSES_SUCCESS:
			return { ...state, snowflakeWarehouses: action.data, fetchingSnowflakeWarehouses: false };

		case DiscoveryConstants.DISCOVERY_GET_SNOWFLAKE_WAREHOUSES_FAIL:
			return { ...state, snowflakeWarehouses: [], fetchingSnowflakeWarehouses: false };

		case DiscoveryConstants.DISCOVERY_GET_SNOWFLAKE_COLUMNS:
			return { ...state, snowflakeColumns: [], fetchingSnowflakeColumns: true };

		case DiscoveryConstants.DISCOVERY_GET_SNOWFLAKE_COLUMNS_SUCCESS:
			return { ...state, snowflakeColumns: action.data, fetchingSnowflakeColumns: false };

		case DiscoveryConstants.DISCOVERY_GET_SNOWFLAKE_COLUMNS_FAIL:
			return { ...state, snowflakeColumns: [], fetchingSnowflakeColumns: false };

		case DiscoveryConstants.DISCOVERY_GET_SNOWFLAKE_MAPPINGS_SUCCESS:
			return { ...state, snowflakeMappings: action.data };

		case DiscoveryConstants.DISCOVERY_GET_SNOWFLAKE_MAPPINGS_FAIL:
			return { ...state, snowflakeMappings: [] };

		default:
			return state;
	}
};
