import concat from 'lodash-es/concat';
import filter from 'lodash-es/filter';
import includes from 'lodash-es/includes';
import map from 'lodash-es/map';
import pickBy from 'lodash-es/pickBy';
import reject from 'lodash-es/reject';
import some from 'lodash-es/some';
import { LOCATION_CHANGE } from 'redux-first-history';

import { RoutingTeamConstants } from '../Constants';

const initialState = {
	fetching: false,
	teamId: null,
	teams: [],
	errors: {},
	locked: false,
};

export default (state = initialState, action = {}) => {
	switch (action.type) {
		case LOCATION_CHANGE:
			return includes(action.payload.location.pathname, '/routing/workflows/') ? state : initialState;

		case RoutingTeamConstants.ROUTING_TEAM_EDIT_MODE:
			return { ...state, team: action.data, teamId: action.teamId };

		case RoutingTeamConstants.ROUTING_TEAM_LIST_REQUEST:
			return { ...state, fetching: true };

		case RoutingTeamConstants.ROUTING_TEAM_LIST_SUCCESS:
			return { ...state, teams: action.data, fetching: false };

		case RoutingTeamConstants.ROUTING_TEAM_LIST_FAIL:
			return { ...state, fetching: false };

		case RoutingTeamConstants.ROUTING_TEAM_CREATE_REQUEST:
			return { ...state, locked: true };

		case RoutingTeamConstants.ROUTING_TEAM_CREATE_SUCCESS:
			return {
				...state,
				teams: concat([], state.teams, action.data),
				locked: false,
				errors: null,
			};

		case RoutingTeamConstants.ROUTING_TEAM_CREATE_FAIL:
			return { ...state, locked: false, errors: action.data };

		case RoutingTeamConstants.ROUTING_TEAM_UPDATE_SUCCESS:
			return {
				...state,
				teams: map(state.teams, team => (team.id === action.data.id ? { ...team, ...action.data } : team)),
			};

		case RoutingTeamConstants.ROUTING_TEAM_UPDATE_FAIL:
			return { ...state, errors: action.error };

		case RoutingTeamConstants.ROUTING_TEAM_DELETE_SUCCESS:
			return { ...state, teams: reject(state.teams, { id: action.id }) };

		case RoutingTeamConstants.ROUTING_TEAM_DELETE_FAIL:
			return state;

		case RoutingTeamConstants.ROUTING_TEAM_MEMBER_CREATE_SUCCESS:
			return {
				...state,
				teams: map(state.teams, team =>
					team.id === action.data.route_team
						? {
								...team,
								route_team_members: concat(
									[],
									action.tmpId
										? reject(team.route_team_members, ['tmpId', action.tmpId])
										: team.route_team_members,
									[action.data]
								),
							}
						: team
				),
				errors: pickBy(state.errors, (_v, k) => action.tmpId !== k),
			};

		case RoutingTeamConstants.ROUTING_TEAM_MEMBER_CREATE_FAIL:
			return {
				...state,
				teams: map(state.teams, team =>
					team.id === action.data.route_team
						? {
								...team,
								route_team_members: some(team.route_team_members, ['tmpId', action.data.tmpId])
									? map(team.route_team_members, m =>
											m.tmpId === action.data.tmpId ? { ...m, ...action.data } : m
										)
									: concat([], team.route_team_members, [action.data]),
							}
						: team
				),
				errors: { ...state.errors, [action.data.tmpId]: action.errors },
			};

		case RoutingTeamConstants.ROUTING_TEAM_MEMBER_UPDATE_SUCCESS:
			const updateMember = member => (action.data.is_default ? { ...member, is_default: false } : member);
			return {
				...state,
				teams: map(state.teams, team =>
					team.id === action.data.route_team
						? {
								...team,
								route_team_members: map(team.route_team_members, member =>
									member.id === action.data.id ? { ...member, ...action.data } : updateMember(member)
								),
							}
						: team
				),
				errors: pickBy(state.errors, (_v, k) => action.data.id !== parseInt(k, 10)),
			};

		case RoutingTeamConstants.ROUTING_TEAM_MEMBER_UPDATE_FAIL:
			return {
				...state,
				teams: map(state.teams, team =>
					team.id === action.data.route_team
						? {
								...team,
								route_team_members: map(team.route_team_members, member =>
									member.id === action.member.id ? { ...member, ...action.member } : member
								),
							}
						: team
				),
				errors: { ...state.errors, [action.rule.id]: action.errors },
			};

		case RoutingTeamConstants.ROUTING_TEAM_MEMBER_DELETE_SUCCESS:
			return {
				...state,
				teams: map(state.teams, team =>
					team.id === action.data.route_team
						? {
								...team,
								route_team_members: filter(
									team.route_team_members,
									member => (member.id || member.tmpId) !== (action.data.id || action.data.tmpId)
								),
							}
						: team
				),
				errors: pickBy(state.errors, (_v, k) => action.data.id !== k && action.data.tmpId !== k),
			};

		case RoutingTeamConstants.ROUTING_TEAM_MEMBER_DELETE_FAIL:
			return state;

		default:
			return state;
	}
};
