import { Route, Switch } from 'react-router';
import RingLeadLoadable from 'ringlead_utils/components/RingLeadLoadable/RingLeadLoadable';

const Login = RingLeadLoadable({
	loader: () => import('./components/LoginHandler'),
});

const ForgotPassword = RingLeadLoadable({
	loader: () => import('./components/ForgotPassword'),
});

const ConfirmResetPassword = RingLeadLoadable({
	loader: () => import('./components/ConfirmResetPassword'),
});

const Register = RingLeadLoadable({
	loader: () => import('./components/Register'),
});

const NotFoundPage = RingLeadLoadable({
	loader: () => import('ringlead_utils/components/NotFoundPage'),
});

function ForgotPasswordRoutes() {
	return (
		<Switch>
			<Route exact path="/auth/forgot_password/" component={ForgotPassword} />
			<Route path="/auth/forgot_password/:UID/:token" component={ConfirmResetPassword} />
		</Switch>
	);
}

function AuthRoutes() {
	return (
		<Switch>
			<Route path="/auth/login" component={Login} />
			<Route path="/auth/forgot_password" component={ForgotPasswordRoutes} />
			<Route path="/auth/register/:UUID" component={Register} />
			<Route path="*" component={NotFoundPage} />
		</Switch>
	);
}

export default <Route path="/auth" component={AuthRoutes} />;
