export const ConnectionConstants = {
	CONNECTION_LIST_SUCCESS: 'CONNECTION_LIST_SUCCESS',
	CONNECTION_LIST_FAIL: 'CONNECTION_LIST_FAIL',
	CONNECTION_UPDATE_SUCCESS: 'CONNECTION_UPDATE_SUCCESS',
	CONNECTION_UPDATE_FAIL: 'CONNECTION_UPDATE_FAIL',
	CONNECTION_CRM_LIST_SUCCESS: 'CONNECTION_CRM_LIST_SUCCESS',
	CONNECTION_CRM_LIST_FAIL: 'CONNECTION_CRM_LIST_FAIL',
	CONNECTION_CRM_FORM_SUCCESS: 'CONNECTION_CRM_FORM_SUCCESS',
	CONNECTION_CRM_FORM_FAIL: 'CONNECTION_CRM_FORM_FAIL',
};

export const ConnectionTablesConstants = {
	TABLE_LIST: 'TABLE_LIST',
	TABLE_LIST_SUCCESS: 'TABLE_LIST_SUCCESS',
	TABLE_LIST_FAIL: 'TABLE_LIST_FAIL',
	TABLE_CHILD_LIST: 'TABLE_CHILD_LIST',
	TABLE_CHILD_LIST_SUCCESS: 'TABLE_CHILD_LIST_SUCCESS',
	TABLE_CHILD_LIST_FAIL: 'TABLE_CHILD_LIST_FAIL',
	TABLE_PARENT_LIST: 'TABLE_PARENT_LIST',
	TABLE_PARENT_LIST_SUCCESS: 'TABLE_PARENT_LIST_SUCCESS',
	TABLE_PARENT_LIST_FAIL: 'TABLE_PARENT_LIST_FAIL',
};

export const ConnectionFieldsConstants = {
	FIELDS_LIST: 'FIELDS_LIST',
	FIELDS_LIST_CLEAR: 'FIELDS_LIST_CLEAR',
	FIELDS_LIST_SUCCESS: 'FIELDS_LIST_SUCCESS',
	FIELDS_LIST_FAIL: 'FIELDS_LIST_FAIL',
};

export const StorageTypes = {
	S3: 's3',
};
