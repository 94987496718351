export const TaskTypes = Object.freeze({
	DEDUPLICATION: 1,
	LIST_IMPORT: 2,
	WEB_SUBMISSION: 3,
	ENRICHMENT: 4,
	NORMALIZATION: 5,
	MASS_UPDATE: 6,
	MASS_DELETE: 7,
	ENRICHMENT_API: 8,
	DISCOVERY: 10,
	UE_TASK: 11,
	INTELLIGENT_FORMS: 13,
	DATA_QUALITY_SCORE: 15,
	DATA_QUALITY_EMPTY: 16,
	TOP_OFF: 19,
} as const);

export const ApplicationTypes = Object.freeze({
	WEBHOOK: 1,
} as const);

export const TaskStatuses = Object.freeze({
	CREATED: 'CREA',
	CANCELED: 'CANCEL',
	NORMAL: 'NORMAL',
	CLEARED: 'CLEARED',
	UPLOAD: 'UPLOAD',
	RUN: 'RUN',
	RESOLUTION: 'RESOLUTION',
	PREVIEW: 'PREVIEW',
	PROCESSING: 'PROCESSING',
	PROCESSING_ALL: 'PROCESSING_ALL',
	NO_MATCH: 'NO_MATCH',
	MERGING: 'MERGING',
	MERGING_ALL: 'MERGING_ALL',
	ERROR: 'ERROR',
	UNRESOLVED_MATCHING_RULES: 'UNRESOLVED_MATCHING_RULES',
	COMPLED: 'COMPL',
	QUEUED: 'QUEUED',
	NO_ENRICHED: 'NO_ENRICHED',
	NO_RECORDS: 'NO_RECORDS',
	DELETING: 'DELETING',
	DELETING_ALL: 'DELETING_ALL',
	UNDELETING: 'UNDELETING',
	UNDELETING_ALL: 'UNDELETING_ALL',
	UPDATING: 'UPDATING',
	UPDATING_ALL: 'UPDATING_ALL',
	ACTIVE: 'Active',
	INACTIVE: 'Inactive',
} as const);

export const NOT_ALLOWED_TASK_STATUSES = [
	TaskStatuses.UPLOAD,
	TaskStatuses.RUN,
	TaskStatuses.QUEUED,
	TaskStatuses.MERGING,
	TaskStatuses.MERGING_ALL,
	TaskStatuses.PROCESSING,
	TaskStatuses.PROCESSING_ALL,
	TaskStatuses.DELETING,
	TaskStatuses.DELETING_ALL,
	TaskStatuses.UNDELETING,
	TaskStatuses.UNDELETING_ALL,
	TaskStatuses.UPDATING,
	TaskStatuses.UPDATING_ALL,
];

export const RESOLUTION_ALLOWED_TASK_STATUSES = [
	TaskStatuses.NORMAL,
	TaskStatuses.RESOLUTION,
	TaskStatuses.MERGING,
	TaskStatuses.MERGING_ALL,
	TaskStatuses.PROCESSING,
	TaskStatuses.PROCESSING_ALL,
	TaskStatuses.DELETING,
	TaskStatuses.DELETING_ALL,
	TaskStatuses.UNDELETING,
	TaskStatuses.UNDELETING_ALL,
	TaskStatuses.UPDATING,
	TaskStatuses.UPDATING_ALL,
	TaskStatuses.COMPLED,
];
