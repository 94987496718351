import { createApi } from '@reduxjs/toolkit/dist/query/react';
import baseQueryWithNProgress from 'app/utils/services/baseQueryWithNProgress';

import type { Notification } from '../types/notification';

export const notificationApi = createApi({
	reducerPath: 'notificationApi',
	baseQuery: baseQueryWithNProgress,
	endpoints: builder => ({
		getNotifications: builder.query<Notification[], void>({
			query: () => '/notification/',
		}),
		getNotification: builder.query<Notification, number>({
			query: id => `/notification/${id}/`,
			async onQueryStarted(id, { dispatch, queryFulfilled }) {
				try {
					const { data } = await queryFulfilled;
					dispatch(
						notificationApi.util.updateQueryData('getNotifications', undefined, draft => {
							if (!draft.find(n => n.id === id)) {
								draft.push(data);
							}
						})
					);
				} catch {
					/* empty */
				}
			},
		}),
		readAllNotifications: builder.mutation<void, void>({
			query: () => '/notification/read_all/',
			onQueryStarted(_, { dispatch }) {
				dispatch(
					notificationApi.util.updateQueryData('getNotifications', undefined, draft =>
						draft.forEach(notification => Object.assign(notification, { unread: false }))
					)
				);
			},
		}),
		removeNotification: builder.mutation<void, number>({
			query: id => ({
				url: `/notification/${id}/`,
				method: 'DELETE',
			}),
			onQueryStarted(id, { dispatch, queryFulfilled }) {
				const patchResult = dispatch(
					notificationApi.util.updateQueryData('getNotifications', undefined, draft =>
						draft.filter(notification => notification.id !== id)
					)
				);
				queryFulfilled.catch(patchResult.undo);
			},
		}),
	}),
});

export const {
	useGetNotificationsQuery,
	useLazyGetNotificationQuery,
	useReadAllNotificationsMutation,
	useRemoveNotificationMutation,
} = notificationApi;
