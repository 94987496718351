import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import * as Sentry from '@sentry/react';
import amplitude from 'amplitude-js';
import { setAdaFields } from 'app/integrations/ada/ada';
import { initPendo } from 'app/integrations/pendo/pendo';
import axios from 'axios';
import browserHistory from 'ringlead_utils/History';

import { environment } from '../../../environments/environment';
import type { User } from '../types/user';
import cookie from '../utils/cookie';

import { authApi } from './auth';

interface AuthState {
	token: string | null;
	user: User;
	tokenIsRefreshing: boolean;
}

const initialState: AuthState = {
	token: cookie.get(`jwtToken${environment.serverName}`) || null,
	// eslint-disable-next-line @typescript-eslint/ban-ts-comment
	// @ts-ignore
	user: null,
	tokenIsRefreshing: false,
};
if (initialState.token) {
	axios.defaults.headers.common.Authorization = `JWT ${initialState.token}`;
}

function initUserWithToken({ token, user }: { token: string; user: User }) {
	cookie.set(`jwtToken${environment.serverName}`, token);
	axios.defaults.headers.common.Authorization = `JWT ${token}`;
	amplitude.getInstance().setUserProperties({
		userId: user.id,
		Email: user.email,
		'First Name': user.first_name,
		'Last Name': user.last_name,
		'Account Id': user.organization.id,
	});
	initPendo(user);
	Sentry.getCurrentScope().setUser({ email: user.email });
	setAdaFields(user);
}

function clearUser() {
	cookie.remove(`jwtToken${environment.serverName}`);
	axios.defaults.headers.common.Authorization = null;
	amplitude.getInstance().clearUserProperties();
	Sentry.getCurrentScope().setUser(null);
}

function domainRedirect(user: User, nextUrl?: string | null, reload?: boolean) {
	const nextUrlParsed = nextUrl ?? '/';
	const customDomain = user.organization.custom_domain;
	const parts = document.location.hostname.split('.');
	if (environment.serverName !== 'local' && customDomain && parts[0] !== customDomain) {
		const domain = `${customDomain}.${parts.slice(-2).join('.')}${nextUrlParsed}`;

		window.location.replace(
			`${window.location.href.substring(0, window.location.href.indexOf('://'))}://${domain}`
		);
	} else if (reload) {
		window.location.reload();
	} else {
		// TODO: Remove this setTimeout once we have a better solution for the redirect
		setTimeout(() => {
			browserHistory.push(nextUrlParsed);
		}, 0);
	}
}

export const authSlice = createSlice({
	name: 'auth',
	initialState,
	reducers: {
		updateUser: (state, action: PayloadAction<Partial<User>>) => {
			if (state.user) {
				Object.assign(state.user, action.payload);
			}
		},
		logout: state => {
			state.token = null;
			// eslint-disable-next-line @typescript-eslint/ban-ts-comment
			// @ts-ignore
			state.user = null;

			clearUser();
		},
	},
	extraReducers: builder => {
		builder.addMatcher(authApi.endpoints.obtainToken.matchFulfilled, (state, { meta, payload }) => {
			state.token = payload.token;
			state.user = payload.user;

			initUserWithToken(payload);
			if (meta.arg.originalArgs.callbackUrl) {
				window.location.href = meta.arg.originalArgs.callbackUrl;
			} else {
				domainRedirect(payload.user, meta.arg.originalArgs.nextUrl);
			}
		});
		builder.addMatcher(authApi.endpoints.socialLogin.matchFulfilled, (state, { payload }) => {
			state.token = payload.token;
			state.user = payload.user;

			initUserWithToken(payload);
			domainRedirect(payload.user);
		});
		builder.addMatcher(authApi.endpoints.loginChangePassword.matchFulfilled, (state, { meta, payload }) => {
			state.token = payload.token;
			state.user = payload.user;

			initUserWithToken(payload);
			domainRedirect(payload.user, meta.arg.originalArgs.nextUrl);
		});
		builder.addMatcher(authApi.endpoints.register.matchFulfilled, (state, { payload }) => {
			state.token = payload.token;
			state.user = payload.user;

			initUserWithToken(payload);
			domainRedirect(payload.user);
		});
		builder.addMatcher(authApi.endpoints.refreshToken.matchFulfilled, (state, { payload }) => {
			state.token = payload.token;
			state.user = payload.user;

			initUserWithToken(payload);
		});
		builder.addMatcher(authApi.endpoints.refreshToken.matchRejected, state => {
			state.token = null;
			// eslint-disable-next-line @typescript-eslint/ban-ts-comment
			// @ts-ignore
			state.user = null;

			clearUser();
		});
		builder.addMatcher(authApi.endpoints.refreshToken.matchPending, state => {
			state.tokenIsRefreshing = true;
		});
		builder.addMatcher(authApi.endpoints.refreshToken.matchFulfilled, state => {
			state.tokenIsRefreshing = false;
		});
		builder.addMatcher(authApi.endpoints.refreshToken.matchRejected, state => {
			state.tokenIsRefreshing = false;
		});
		builder.addMatcher(authApi.endpoints.changePassword.matchFulfilled, (state, { payload }) => {
			state.token = payload.token;
			state.user = payload.user;

			initUserWithToken(payload);
			domainRedirect(payload.user);
		});
		builder.addMatcher(authApi.endpoints.maskLogin.matchFulfilled, (state, { payload }) => {
			state.token = payload.token;
			state.user = payload.user;

			initUserWithToken(payload);
			domainRedirect(payload.user, null, true);
		});
		builder.addMatcher(authApi.endpoints.maskLogout.matchFulfilled, (state, { payload }) => {
			state.token = payload.token;
			state.user = payload.user;

			initUserWithToken(payload);
			domainRedirect(payload.user, null, true);
		});
		builder.addMatcher(authApi.endpoints.updateCurrentUser.matchFulfilled, (state, { payload }) => {
			if (state.user) {
				Object.assign(state.user, payload);
			}
		});
		builder.addMatcher(authApi.endpoints.logout.matchPending, state => {
			state.token = null;
			// eslint-disable-next-line @typescript-eslint/ban-ts-comment
			// @ts-ignore
			state.user = null;
		});
		builder.addMatcher(authApi.endpoints.logout.matchFulfilled, () => {
			clearUser();
		});
		builder.addMatcher(authApi.endpoints.logout.matchRejected, () => {
			clearUser();
		});
	},
});

export const { updateUser, logout } = authSlice.actions;

export default authSlice.reducer;
