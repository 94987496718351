import Cookies from 'js-cookie';

import { environment } from '../../../environments/environment';

const cookie = Cookies.withAttributes({
	domain: `.${document.location.hostname.split('.').slice(-2).join('.')}`,
	path: '/',
	sameSite: 'lax',
	expires: 2,
	secure: environment.serverName !== 'local',
});

export default cookie;
