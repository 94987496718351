import Spinner from './Spinner';

function Preloader() {
	return (
		<Spinner
			size={64}
			sx={{ margin: 'auto', position: 'absolute', top: 'calc(45% - 32px)', left: 'calc(50% - 32px)' }}
		/>
	);
}

export default Preloader;
