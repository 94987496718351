import { createApi } from '@reduxjs/toolkit/dist/query/react';
import cookie from 'app/auth/utils/cookie';
import baseQueryWithNProgress from 'app/utils/services/baseQueryWithNProgress';
import { enqueueSnackbar } from 'notistack';

import { environment } from '../../../environments/environment';
import type { User } from '../types/user';

export interface AuthResponse {
	token: string;
	user: User;
}

export interface ObtainTokenArgs {
	email: string;
	password: string;
	callbackUrl: string | null;
	nextUrl: string | null;
	totpToken?: string;
}

interface LoginChangePasswordArgs {
	email: string;
	password: string;
	newPassword: string;
	newPassword1: string;
	nextUrl: string | null;
}

interface RegisterArgs {
	activation_code: string;
	email: string;
	accepted_msa_agreement: boolean;
	first_name: string;
	last_name: string;
	mobile_number: string;
	password: string;
	password1: string;
}

interface ChangePasswordArgs {
	uidb64: string;
	token: string;
	body: { password: string; password1: string };
}

export const authApi = createApi({
	reducerPath: 'authApi',
	baseQuery: baseQueryWithNProgress,
	endpoints: builder => ({
		obtainToken: builder.mutation<AuthResponse, ObtainTokenArgs>({
			query: ({ email, password, totpToken, callbackUrl }) => ({
				url: '/account/token/obtain/',
				method: 'POST',
				body: { email, password, totp_token: totpToken, eloqua_callback_url: callbackUrl },
			}),
		}),
		socialLogin: builder.mutation<
			AuthResponse,
			{ provider: string; code: string; accepted_msa_agreement: boolean }
		>({
			query: body => ({
				url: '/socializer/token/',
				method: 'POST',
				body,
			}),
		}),
		loginChangePassword: builder.mutation<AuthResponse, LoginChangePasswordArgs>({
			query: ({ email, password, newPassword, newPassword1 }) => ({
				url: '/account/token/change-password/',
				method: 'POST',
				body: { email, password, new_password: newPassword, new_password1: newPassword1 },
			}),
		}),
		register: builder.mutation<AuthResponse, RegisterArgs>({
			query: body => ({
				url: '/account/token/register/',
				method: 'POST',
				body,
			}),
		}),
		refreshToken: builder.mutation<AuthResponse, void>({
			query: () => ({
				url: '/account/token/refresh/',
				method: 'POST',
				body: { token: cookie.get(`jwtToken${environment.serverName}`) },
			}),
		}),
		forgotPasswordSend: builder.mutation<unknown, { email: string }>({
			query: body => ({
				url: '/account/change-password/',
				method: 'POST',
				body,
			}),
			async onQueryStarted({ email }, { queryFulfilled }) {
				try {
					await queryFulfilled;
					enqueueSnackbar({
						key: `reset_pwd_${email}`,
						message: 'Instructions successfully sent to your email',
						variant: 'success',
					});
				} catch {
					/* empty */
				}
			},
		}),
		changePassword: builder.mutation<AuthResponse, ChangePasswordArgs>({
			query: ({ uidb64, token, body }) => ({
				url: `/account/reset_password_confirm/${uidb64}-${token}/`,
				method: 'PUT',
				body,
			}),
		}),
		maskLogin: builder.mutation<AuthResponse, { email: string }>({
			query: body => ({
				url: '/account/mask-user/',
				method: 'POST',
				body,
			}),
		}),
		maskLogout: builder.mutation<AuthResponse, void>({
			query: () => ({
				url: '/account/unmask-user/',
				method: 'POST',
				body: {},
			}),
		}),
		updateCurrentUser: builder.mutation<User, Partial<User>>({
			query: body => ({
				url: '/account/user/',
				method: 'PATCH',
				body,
			}),
		}),
		logout: builder.mutation<unknown, void>({
			query: () => ({
				url: '/account/token/remove/',
				method: 'POST',
				body: {},
			}),
		}),
	}),
});

export const {
	endpoints,
	useObtainTokenMutation,
	useSocialLoginMutation,
	useLoginChangePasswordMutation,
	useRefreshTokenMutation,
	useRegisterMutation,
	useForgotPasswordSendMutation,
	useChangePasswordMutation,
	useMaskLoginMutation,
	useMaskLogoutMutation,
	useUpdateCurrentUserMutation,
	useLogoutMutation,
} = authApi;
