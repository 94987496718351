import { EventsConstants } from 'app/dop/Constants';

const initialState = {
	fetchingTargetFields: true,
	targetFields: [],
	usedFields: {},
};

export default (state = initialState, action = {}) => {
	switch (action.type) {
		case EventsConstants.GET_EVENT_MAPPING_TARGET_FIELDS:
			return { ...state, fetchingTargetFields: true, targetFields: [] };

		case EventsConstants.GET_EVENT_MAPPING_TARGET_FIELDS_SUCCESS:
			return {
				...state,
				fetchingTargetFields: false,
				targetFields: action.data,
				usedFields: { ...state.usedFields, [`${action.connectionId}_${action.table}`]: action.data },
			};

		case EventsConstants.GET_EVENT_MAPPING_TARGET_FIELDS_FAIL:
			return {
				...state,
				fetchingTargetFields: false,
				targetFields: [],
				usedFields: { ...state.usedFields, [`${action.connectionId}_${action.table}`]: [] },
			};

		case EventsConstants.GET_EVENT_USE_EXISTING_FIELDS:
			return {
				...state,
				[action.fieldsType]: state.usedFields[`${action.connectionId}_${action.table}`],
			};

		default:
			return state;
	}
};
