import concat from 'lodash-es/concat';
import filter from 'lodash-es/filter';
import find from 'lodash-es/find';
import includes from 'lodash-es/includes';
import isArray from 'lodash-es/isArray';
import map from 'lodash-es/map';
import reject from 'lodash-es/reject';
import { LOCATION_CHANGE } from 'redux-first-history';

import { RoundRobinConstants } from '../Constants';

const initialState = {
	roundRobins: [],
	fetching: false,
	locked: false,
	bulkCreating: false,
};

export default (state = initialState, action = {}) => {
	switch (action.type) {
		case LOCATION_CHANGE:
			if (includes(action.payload.location.pathname, 'routing/workflows')) {
				return state;
			}

			return initialState;

		case RoundRobinConstants.ROUND_ROBIN_FETCHING:
			return { ...state, fetching: true };

		case RoundRobinConstants.ROUND_ROBIN_LIST_SUCCESS:
			return { ...state, roundRobins: action.data, fetching: false };

		case RoundRobinConstants.ROUND_ROBIN_LIST_FAIL:
			return { ...state, roundRobins: [], fetching: false };

		case RoundRobinConstants.ROUND_ROBIN_RETRIEVE_SUCCESS:
			return { ...state, roundRobins: [action.data], fetching: false };

		case RoundRobinConstants.ROUND_ROBIN_RETRIEVE_FAIL:
			return { ...state, roundRobins: [], fetching: false };

		case RoundRobinConstants.ROUND_ROBIN_CREATE:
			return { ...state, locked: true };

		case RoundRobinConstants.ROUND_ROBIN_BULK_CREATE:
			return { ...state, bulkCreating: true };

		case RoundRobinConstants.ROUND_ROBIN_BULK_CREATE_SUCCESS:
			return { ...state, bulkCreating: false };

		case RoundRobinConstants.ROUND_ROBIN_CREATE_SUCCESS:
			return { ...state, locked: false };

		case RoundRobinConstants.ROUND_ROBIN_BULK_CREATE_FAIL:
		case RoundRobinConstants.ROUND_ROBIN_CREATE_FAIL:
			return { ...state, locked: false };

		case RoundRobinConstants.ROUND_ROBIN_UPDATE_SUCCESS:
			return {
				...state,
				roundRobins: map(state.roundRobins, roundRobin =>
					roundRobin.id === action.data.id ? { ...roundRobin, ...action.data } : roundRobin
				),
			};

		case RoundRobinConstants.ROUND_ROBIN_UPDATE_FAIL:
			return state;

		case RoundRobinConstants.ROUND_ROBIN_DELETE_SUCCESS:
			return { ...state, roundRobins: reject(state.roundRobins, { id: action.id }) };

		case RoundRobinConstants.ROUND_ROBIN_DELETE_FAIL:
			return state;

		case RoundRobinConstants.ROUND_ROBIN_USER_ADD_SUCCESS:
			return {
				...state,
				roundRobins: map(state.roundRobins, rr =>
					rr.id === action.data.round_robin
						? {
								...rr,
								round_robin_users: concat([], rr.round_robin_users, action.data),
							}
						: rr
				),
			};

		case RoundRobinConstants.ROUND_ROBIN_USER_ADD_FAIL:
			return state;

		case RoundRobinConstants.ROUND_ROBIN_USER_DELETE_SUCCESS:
			return {
				...state,
				roundRobins: map(state.roundRobins, rr => ({
					...rr,
					round_robin_users: filter(
						rr.round_robin_users,
						roundRobinUser => !includes(action.ids, roundRobinUser.id)
					),
				})),
			};

		case RoundRobinConstants.ROUND_ROBIN_USER_UPDATE_SUCCESS:
			const actionData = isArray(action.data) ? action.data : [action.data];
			return {
				...state,
				roundRobins: map(state.roundRobins, rr => ({
					...rr,
					round_robin_users: map(rr.round_robin_users, roundRobinUser => {
						const actionRoundRobin = find(actionData, ['id', roundRobinUser.id]);

						return actionRoundRobin ? { ...roundRobinUser, ...actionRoundRobin } : roundRobinUser;
					}),
				})),
			};

		default:
			return state;
	}
};
