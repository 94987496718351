import cookie from 'app/auth/utils/cookie';
import Pusher from 'pusher-js';

import { environment } from '../../../environments/environment';

const pusher = new Pusher(environment.pusherKey, {
	cluster: environment.pusherCluster,
	forceTLS: true,
	channelAuthorization: {
		transport: 'ajax',
		endpoint: `${environment.apiBaseUrl}account/token/pusher/`,
		customHandler: (data, callback) => {
			const token = cookie.get(`jwtToken${environment.serverName}`);

			if (!token) {
				callback(new Error('JWT Token missing'), null);
				return;
			}

			fetch(`${environment.apiBaseUrl}account/token/pusher/`, {
				method: 'POST',
				body: JSON.stringify({
					channel_name: data.channelName,
					socket_id: data.socketId,
				}),
				headers: {
					authorization: `JWT ${token}`,
					'content-type': 'application/json',
				},
			})
				.then(response => response.json())
				.then(response => callback(null, response))
				.catch(err => callback(err, null));
		},
	},
});

export default pusher;
