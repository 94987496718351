import { ConnectionTypes } from 'app/settings/constants/ConnectionConstants';
import endsWith from 'lodash-es/endsWith';
import filter from 'lodash-es/filter';
import find from 'lodash-es/find';
import includes from 'lodash-es/includes';
import map from 'lodash-es/map';
import memoize from 'lodash-es/memoize';
import startsWith from 'lodash-es/startsWith';
import toLower from 'lodash-es/toLower';
import unionBy from 'lodash-es/unionBy';

const getMatchedAccountObject = (task, mappedObjects) => {
	if (task.matched_account_option === 'DO_NOTHING') {
		return null;
	}

	if (
		task.matched_account_option !== 'LINK_LEAD_TO_ACCOUNT' &&
		startsWith(task.matched_account_option, 'LINK_') &&
		endsWith(task.matched_account_option, '_TO_ACCOUNT')
	) {
		return (
			find(
				mappedObjects,
				obj =>
					toLower(obj.name) ===
					toLower(task.matched_account_option.substring(5, task.matched_account_option.length - 11))
			) || null
		);
	}

	return task.matched_account_option === 'CREATE_DIRECT_CONTACT'
		? { name: 'Contact', label: 'Contact' }
		: { name: 'Lead', label: 'Lead' };
};

const getSalesforceObjects = (task, mappedObjects) => {
	const customObjects = filter(
		task.search_objects,
		searchObject => !includes(['Account', 'Lead', 'Contact'], searchObject)
	);

	const objects = [];

	if (
		customObjects.length === 0 ||
		(includes(task.search_objects, 'Lead') && !(includes(task.search_objects, 'Account') && task.create_accounts))
	) {
		objects.push({ name: 'Lead', label: 'Lead' });
	}

	if (includes(task.search_objects, 'Contact')) {
		objects.push({ name: 'Contact', label: 'Contact' });
	}

	if (includes(task.search_objects, 'Account')) {
		objects.push({ name: 'Account', label: 'Account' });
	}

	if (includes(task.search_objects, 'Account') && task.create_accounts) {
		const matchedAccountObject = getMatchedAccountObject(task, mappedObjects);
		if (matchedAccountObject) {
			objects.push(matchedAccountObject);
		}
	} else if (customObjects.length === 0 || includes(task.search_objects, 'Lead')) {
		objects.push({ name: 'Lead', label: 'Lead' });
	}

	return unionBy(
		[],
		objects,
		map(
			customObjects,
			customObject => find(mappedObjects, ['name', customObject]) || { name: customObject, label: customObject }
		),
		'name'
	);
};

const getHubSpotObjects = task => {
	const objects = [];

	if (includes(task.search_objects, 'Contact') || task.create_new_contacts) {
		objects.push({ name: 'Contact', label: 'Contact' });
	}

	if (includes(task.search_objects, 'Company') || task.create_new_companys) {
		objects.push({ name: 'Company', label: 'Company' });
	}

	return objects;
};

export const getObjects = (task, mappedObjects) => {
	if (!task.connection) {
		return [{ name: 'Lead', label: 'Lead' }];
	}

	if (task.connection.type === ConnectionTypes.ELOQUA) {
		return [{ name: 'Contact', label: 'Contact' }];
	}

	if (task.connection.type === ConnectionTypes.HUBSPOT) {
		return getHubSpotObjects(task);
	}

	return getSalesforceObjects(task, mappedObjects);
};

const getOperationObjectsContactMatch = task => (task.update_related_account ? ['Contact', 'Account'] : ['Contact']);

const getOperationObjectsAccountMatch = task => {
	if (task.matched_account_option === 'DO_NOTHING') {
		return ['Account'];
	}

	const objects = ['Account'];
	if (
		task.matched_account_option !== 'LINK_LEAD_TO_ACCOUNT' &&
		startsWith(task.matched_account_option, 'LINK_') &&
		endsWith(task.matched_account_option, '_TO_ACCOUNT')
	) {
		const customObject = find(
			task.search_objects,
			obj =>
				toLower(obj) ===
				toLower(task.matched_account_option.substring(5, task.matched_account_option.length - 11))
		);
		if (customObject) {
			objects.push(customObject);
		}
	} else {
		objects.push(task.matched_account_option === 'CREATE_DIRECT_CONTACT' ? 'Contact' : 'Lead');
	}
	return objects;
};

const getOperationObjectsLeadMatch = (task, masterRecord) =>
	task.matched_account_option === 'LINK_LEAD_TO_ACCOUNT' &&
	task.lead_to_account_field &&
	task.update_linked_account &&
	masterRecord?.[task.lead_to_account_field]
		? ['Lead', 'Account']
		: ['Lead'];

const getOperationObjectsNoMatch = (task, mappedObjects) => {
	if (includes(task.search_objects, 'Account') && task.create_accounts) {
		return getOperationObjectsAccountMatch(task);
	}

	return map(mappedObjects, 'name') || ['Lead'];
};

const getOperationObjectsCustomMatch = record => [record.cluster.object, 'Lead'];

const getSalesforceOperationObjects = (task, mappedObjects, record) => {
	switch (record.cluster ? record.cluster.object : null) {
		case 'Contact':
			return getOperationObjectsContactMatch(task);
		case 'Lead':
			return getOperationObjectsLeadMatch(
				task,
				record.cluster ? find(record.cluster.records, ['Id', record.cluster.master]) : null
			);
		case 'Account':
			return getOperationObjectsAccountMatch(task);
		case null:
			return getOperationObjectsNoMatch(task, mappedObjects);
		default:
			return getOperationObjectsCustomMatch(record);
	}
};

const getHubSpotOperationObjects = (task, record) => {
	if (record.cluster) {
		return [record.cluster.object];
	}

	return (
		filter(task.search_objects, searchObject => task[`create_new_${searchObject.toLowerCase()}s`]) || ['Contact']
	);
};

export const getOperationObjects = memoize(
	(task, mappedObjects, record) => {
		switch (task.connection.type) {
			case ConnectionTypes.SALESFORCE:
			case ConnectionTypes.DYNAMICS:
				return getSalesforceOperationObjects(task, mappedObjects, record);
			case ConnectionTypes.ELOQUA:
				return ['Contact'];
			case ConnectionTypes.HUBSPOT:
				return getHubSpotOperationObjects(task, record);
			default:
				return [];
		}
	},
	(_task, _mappedObjects, record) =>
		record.cluster ? `${record.id}_${record.cluster.object}_${record.cluster.master}` : record.id
);

export const getEnrichedValue = (field, researchResponse) =>
	researchResponse && researchResponse[field.data_append_field];

export const getNormalizedValue = (field, researchResponse) =>
	researchResponse && researchResponse[`NORMALIZED___${field.sf_field.table}.${field.sf_field.name}`];

export const getSegmentedValue = (field, researchResponse, segmentationFields) =>
	researchResponse &&
	segmentationFields &&
	includes(segmentationFields, `${field.sf_field.table}.${field.sf_field.name}`) &&
	researchResponse[`SEGMENTED___${field.sf_field.table}.${field.sf_field.name}`];

export const getScoredValue = (field, researchResponse, scoreFields) =>
	researchResponse &&
	scoreFields &&
	includes(scoreFields, `${field.sf_field.table}.${field.sf_field.name}`) &&
	researchResponse[`SCORED___${field.sf_field.table}.${field.sf_field.name}`];
