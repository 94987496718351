import concat from 'lodash-es/concat';
import filter from 'lodash-es/filter';
import find from 'lodash-es/find';
import forEach from 'lodash-es/forEach';
import includes from 'lodash-es/includes';
import invokeMap from 'lodash-es/invokeMap';
import map from 'lodash-es/map';
import pickBy from 'lodash-es/pickBy';
import reduce from 'lodash-es/reduce';
import { LOCATION_CHANGE } from 'redux-first-history';

import { MatchingRulesConstants } from '../Constants';

const initialState = {
	matchingRules: [],
	fetching: true,
	errors: {},
};

export default (state = initialState, action = {}) => {
	switch (action.type) {
		case LOCATION_CHANGE:
			return initialState;

		case MatchingRulesConstants.LOAD_MATCHING_RULES_SUCCESS:
			return { ...state, matchingRules: action.data, fetching: false };

		case MatchingRulesConstants.LOAD_MATCHING_RULES_FAIL:
			return { ...state, matchingRules: [], fetching: false };

		case MatchingRulesConstants.CREATE_MATCHING_RULE_SUCCESS: {
			let { matchingRules } = state;

			forEach(action.rules, (rule, index) => {
				matchingRules = find(matchingRules, ['tmpId', rule.tmpId])
					? map(matchingRules, matchingRule =>
							matchingRule.tmpId === rule.tmpId
								? { ...matchingRule, ...action.data[index] }
								: matchingRule
						)
					: concat([], matchingRules, action.data[index]);
			});

			return {
				...state,
				matchingRules,
				errors: pickBy(state.errors, (_v, k) => !includes(map(action.rules, 'tmpId'), k)),
			};
		}

		case MatchingRulesConstants.CREATE_MATCHING_RULE_FAIL: {
			let { matchingRules } = state;

			forEach(action.rules, rule => {
				if (!includes(map(matchingRules, 'tmpId'), rule.tmpId)) {
					matchingRules = concat([], matchingRules, rule);
				} else {
					matchingRules = map(matchingRules, matchingRule => {
						const actionRule = find(action.rules, ['tmpId', matchingRule.tmpId]);

						return actionRule ? { ...matchingRule, ...actionRule } : matchingRule;
					});
				}
			});

			return {
				...state,
				matchingRules,
				errors: {
					...state.errors,
					...reduce(
						action.rules,
						(result, rule, index) => ({ ...result, [rule.tmpId]: action.data[index] || {} }),
						{}
					),
				},
			};
		}

		case MatchingRulesConstants.PATCH_MATCHING_RULE_SUCCESS: {
			const allMatchingRulesIds = map(action.rules, rule => rule.id.toString());

			return {
				...state,
				matchingRules: map(state.matchingRules, matchingRule => {
					const actionRule = find(action.data, ['id', matchingRule.id]);

					return actionRule ? { ...matchingRule, ...actionRule } : matchingRule;
				}),
				errors: pickBy(state.errors, (_v, k) => !includes(allMatchingRulesIds, k)),
			};
		}

		case MatchingRulesConstants.PATCH_MATCHING_RULE_FAIL:
			return {
				...state,
				matchingRules: map(state.matchingRules, matchingRule => {
					const actionRule = find(action.rules, ['id', matchingRule.id]);

					return actionRule ? { ...matchingRule, ...actionRule } : matchingRule;
				}),
				errors: {
					...state.errors,
					...reduce(
						action.rules,
						(result, rule, index) => ({ ...result, [rule.id]: action.data[index] || {} }),
						{}
					),
				},
			};

		case MatchingRulesConstants.DELETE_MATCHING_RULE_SUCCESS: {
			const allMatchingRulesIds = invokeMap(action.ids, 'toString');

			return {
				...state,
				matchingRules: filter(
					state.matchingRules,
					matchingRule => !includes(action.ids, matchingRule.id) && !includes(action.ids, matchingRule.tmpId)
				),
				errors: pickBy(state.errors, (_v, k) => !includes(allMatchingRulesIds, k)),
			};
		}

		case MatchingRulesConstants.DELETE_MATCHING_RULE_FAIL:
			return state;

		default:
			return state;
	}
};
