import { cloneElement, PureComponent, type ReactElement } from 'react';

class RingLeadLoadableErrorBoundary extends PureComponent<{ children: ReactElement }, { hasError: boolean }> {
	constructor(props: { children: ReactElement }) {
		super(props);

		this.state = { hasError: false };
	}

	static getDerivedStateFromError() {
		return { hasError: true };
	}

	componentDidCatch() {
		const wasReloaded = localStorage.getItem('autoreloadOnError');

		if (wasReloaded) {
			localStorage.removeItem('autoreloadOnError');
			return;
		}

		localStorage.setItem('autoreloadOnError', '1');
		window.location.reload();
	}

	render() {
		if (this.state.hasError) {
			return (
				<div>
					Looks like you application is outdated or has an error. Please refresh the page:{' '}
					<button type="button" onClick={() => window.location.reload()}>
						Reload
					</button>
				</div>
			);
		}

		return cloneElement(this.props.children);
	}
}

export default RingLeadLoadableErrorBoundary;
