import forEach from 'lodash-es/forEach';
import includes from 'lodash-es/includes';
import pickBy from 'lodash-es/pickBy';
import { LOCATION_CHANGE } from 'redux-first-history';

import { ResolutionSettingsConstants } from '../Constants';
import {
	getFieldsSort,
	getFieldToShow,
	getLimit,
	getMapping,
	getResulutionStorageKey,
	getShowing,
	getTableOrientation,
} from '../helpers/ResolutionSettingsHelper';

const initialState = {
	isPrepared: false,
	limit: 5,
	tableOrientation: 'v',
	showing: {},
	fieldToShow: {},
	fieldSort: {},
	sfMapping: [],
};

export default (state = initialState, action = {}) => {
	switch (action.type) {
		case LOCATION_CHANGE:
			if (
				includes(action.payload.location.pathname, 'resolution') &&
				!includes(action.payload.location.pathname, 'auth')
			) {
				return state;
			}

			return initialState;

		case ResolutionSettingsConstants.GET_RESOLUTION_SETTINGS:
			return {
				...state,
				isPrepared: true,
				limit: getLimit(action.task),
				tableOrientation: getTableOrientation(action.task),
				showing: getShowing(action.task),
				fieldToShow: getFieldToShow(action.task, action.extra),
				fieldSort: getFieldsSort(action.task, action.extra),
				sfMapping: getMapping(action.task, action.extra.fields, action.extra.survivingFieldValueRules),
			};

		case ResolutionSettingsConstants.UPDATE_RESOLUTION_SETTINGS:
			if (action.data.limit) {
				localStorage.setItem(getResulutionStorageKey(action.task, 'limit'), action.data.limit);
			}

			if (action.data.tableOrientation) {
				localStorage.setItem(
					getResulutionStorageKey(action.task, 'table_orientation'),
					action.data.tableOrientation
				);
			}

			if (action.data.showing) {
				forEach(action.data.showing, (v, k) => {
					localStorage.setItem(getResulutionStorageKey(action.task, `showing_${k}`), v);
				});
			}

			if (action.data.fieldSort) {
				localStorage.setItem(
					getResulutionStorageKey(action.task, 'field_sort'),
					JSON.stringify(action.data.fieldSort)
				);
			}

			if (action.data.fieldToShow) {
				localStorage.setItem(
					getResulutionStorageKey(action.task, 'field_to_show'),
					JSON.stringify(action.data.fieldToShow)
				);
			}

			return { ...state, ...pickBy(action.data, dt => !!dt) };

		default:
			return state;
	}
};
