import { LOCATION_CHANGE } from 'redux-first-history';

import { RoutingActivityConstants } from '../Constants';

const initialState = {
	fetching: true,
	routingChartData: {},
};

export default (state = initialState, action = {}) => {
	switch (action.type) {
		case LOCATION_CHANGE:
			return initialState;

		case RoutingActivityConstants.ROUTING_DASHBOARD_DATA_SUCCESS:
			return { ...state, routingChartData: action.data, fetching: false };

		case RoutingActivityConstants.ROUTING_DASHBOARD_DATA_FAIL:
			return { ...state, routingChartData: {}, fetching: false };

		default:
			return state;
	}
};
