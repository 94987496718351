import { yellow } from '@mui/material/colors';
import createTheme from '@mui/material/styles/createTheme';
import { renderTimeViewClock } from '@mui/x-date-pickers-pro';

import DatePickerField from './ui-kit/DatePickerField/DatePickerField';

const RingLeadPrimaryColors = {
	main: '#006eb9',

	50: '#f4f6fc',
	100: '#b2e3fe',
	200: '#7fd1fc',
	300: '#49bffa',
	400: '#1eb2f9',
	500: '#00a4f7',
	600: '#0096e7',
	700: '#0083d3',
	800: '#006eb9',
	900: '#04529d',
};

const RingLeadSecondaryColors = {
	main: '#f10091',

	50: '#fbe2f0',
	100: '#f6b7da',
	200: '#f386c1',
	300: '#f14fa7',
	400: '#f10091',
	500: '#f30079',
	600: '#e10076',
	700: '#ca0070',
	800: '#b4006b',
	900: '#8b0063',
};

const autofillBoxShadow = '0 0 0 30px white inset !important';

const muitheme = createTheme({
	palette: {
		action: {
			hover: RingLeadPrimaryColors['50'],
		},
		background: {
			default: RingLeadPrimaryColors['50'],
		},
		primary: RingLeadPrimaryColors,
		secondary: RingLeadSecondaryColors,
		error: RingLeadSecondaryColors,
		warning: {
			main: yellow[50],
		},
		prevent: '#322893',
		cleanse: '#0072BF',
		enrich: '#722282',
	},
});

const theme = createTheme(muitheme, {
	components: {
		MuiCssBaseline: {
			styleOverrides: {
				body: {
					'*::-webkit-scrollbar': {
						backgroundColor: '#fff',
						width: '16px',
					},
					'*::-webkit-scrollbar-track': {
						backgroundColor: '#fff',
					},
					'*::-webkit-scrollbar-track:hover': {
						backgroundColor: '#f4f4f4',
					},
					'*::-webkit-scrollbar-thumb': {
						backgroundColor: '#babac0',
						borderRadius: '16px',
						minHeight: '40px',
						border: '5px solid #fff',
					},
					'*::-webkit-scrollbar-thumb:hover': {
						backgroundColor: '#a0a0a5',
						borderRadius: '16px',
						border: '4px solid #f4f4f4',
					},
					html: {
						color: muitheme.palette.text.primary,
						fontFamily: muitheme.typography.fontFamily,
						fontWeight: muitheme.typography.body2.fontWeight,
						lineHeight: muitheme.typography.body2.lineHeight,
						letterSpacing: muitheme.typography.body2.letterSpacing,
					},
					'input:focus::-webkit-contacts-auto-fill-button': {
						opacity: 0,
					},
					'input::-webkit-clear-button': {
						display: 'none',
					},
					'input:-webkit-autofill': {
						'-webkit-box-shadow': autofillBoxShadow,
					},
					'input:-webkit-autofill:hover': {
						'-webkit-box-shadow': autofillBoxShadow,
					},
					'input:-webkit-autofill:focus': {
						'-webkit-box-shadow': autofillBoxShadow,
					},
					'input:-webkit-autofill:active': {
						'-webkit-box-shadow': autofillBoxShadow,
					},
					p: {
						marginBlockStart: 0,
						marginBlockEnd: 0,
					},
				},
			},
		},
		MuiAlert: {
			defaultProps: {
				variant: 'filled',
			},
			styleOverrides: {
				filledError: {
					backgroundColor: muitheme.palette.secondary.main,
				},
				filledInfo: {
					backgroundColor: muitheme.palette.primary.main,
				},
				filledSuccess: {
					backgroundColor: muitheme.palette.primary.main,
				},
				filledWarning: {
					backgroundColor: yellow[800],
				},
			},
		},
		MuiAutocomplete: {
			defaultProps: {
				size: 'small',
				ChipProps: {
					color: 'primary',
				},
			},
		},
		MuiBreadcrumbs: {
			styleOverrides: {
				root: {
					fontSize: '1rem',
					flex: '1 1 auto',
					color: RingLeadPrimaryColors['50'],
				},
				li: {
					display: 'inline-block',
					// fontSize: '1rem',
					maxWidth: '22em',
					whiteSpace: 'nowrap',
					textOverflow: 'ellipsis',
					overflow: 'hidden',
					fontWeight: 'bold',
					textDecoration: 'none',
					'&:last': {
						fontWeight: 'bolder',
					},
					'& > a': {
						color: RingLeadPrimaryColors['50'],
						textDecoration: 'none',
					},
				},
			},
		},
		MuiOutlinedInput: {
			defaultProps: {
				margin: 'dense',
			},
		},
		MuiSelect: {
			styleOverrides: {
				select: {
					'&:focus': {
						backgroundColor: 'transparent',
					},
				},
			},
		},
		MuiDatePicker: {
			defaultProps: {
				slots: {
					textField: DatePickerField,
					openPickerButton: () => null,
				},
			},
		},
		MuiDateTimePicker: {
			defaultProps: {
				slots: {
					textField: DatePickerField,
					openPickerButton: () => null,
				},
				viewRenderers: {
					hours: renderTimeViewClock,
					minutes: renderTimeViewClock,
					seconds: renderTimeViewClock,
				},
			},
		},
		MuiTimePicker: {
			defaultProps: {
				slots: {
					textField: DatePickerField,
					openPickerButton: () => null,
				},
				viewRenderers: {
					hours: renderTimeViewClock,
					minutes: renderTimeViewClock,
					seconds: renderTimeViewClock,
				},
			},
		},
		MuiDateRangePicker: {
			defaultProps: {
				slotProps: {
					field: {
						readOnly: true,
					},
				},
			},
		},
		MuiDataGrid: {
			defaultProps: {
				columnHeaderHeight: 48,
				rowHeight: 48,
				disableColumnReorder: true,
				disableColumnResize: true,
				disableColumnMenu: true,
				disableColumnFilter: true,
				disableSelectionOnClick: true,
				disableDensitySelector: true,
				hideFooterRowCount: true,
				hideFooterSelectedRowCount: true,
				disableRowSelectionOnClick: true,
				slotProps: {
					rowReorderIcon: {
						fontSize: 'medium',
					},
				},
			},
			styleOverrides: {
				root: {
					'& .MuiDataGrid-actionsCell': {
						gridGap: 0,
						'& .MuiSvgIcon-root': {
							fontSize: 'inherit',
						},
					},
					'& .MuiDataGrid-row--editing': {
						boxShadow: 'none',
					},
					'& .MuiDataGrid-cell--editing': {
						boxShadow: 'none !important',
					},
				},
				columnHeaders: {
					backgroundColor: RingLeadPrimaryColors['50'],
				},
				columnHeader: {
					'&:focus': {
						outline: 'none',
					},
					'&:focus-within': {
						outline: 'none',
					},
				},
				pinnedRows: {
					backgroundColor: muitheme.palette.common.white,
				},
				columnSeparator: {
					display: 'none',
				},
				cell: {
					'&:focus': {
						outline: 'none',
					},
					'&:focus-within': {
						outline: 'none !important',
					},
				},
				footerContainer: {
					padding: muitheme.spacing(0, 2),
				},
			},
		},
	},
});

export default theme;
