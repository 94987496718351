export const HELP_CENTER_URL = 'https://www.zoominfo.com/about/help-center';
export const HELP_CENTER_DISCOVER_URL = '';
export const HELP_CENTER_ROUTE_URL = 'https://help.zoominfo.com/lightning/articles/help/Overview-of-RingLead-Route';
export const HELP_CENTER_SEGMENT_URL = 'https://help.zoominfo.com/s/article/Overview-of-RingLead-Segment';

export const HELP_CENTER_ANALYZE_PAGE = { label: 'Analyze', url: '/analyze/' };
export const HELP_CENTER_CLEANSE_PAGE = { label: 'Cleanse', url: '/cleanse/' };
export const HELP_CENTER_PREVENT_PAGE = { label: 'Prevent', url: '/prevent/' };
export const HELP_CENTER_ENRICH_PAGE = { label: 'Enrich', url: '/enrich/' };
export const HELP_CENTER_DISCOVER_PAGE = { label: 'Discover', url: '/discover/' };
export const HELP_CENTER_ROUTE_PAGE = { label: 'Route', url: '/route/' };
export const HELP_CENTER_SEGMENT_PAGE = { label: 'Transform', url: '/transform/' };

export const HELP_CENTER_PAGES = [
	HELP_CENTER_ANALYZE_PAGE,
	HELP_CENTER_CLEANSE_PAGE,
	HELP_CENTER_PREVENT_PAGE,
	HELP_CENTER_ENRICH_PAGE,
	HELP_CENTER_DISCOVER_PAGE,
	HELP_CENTER_ROUTE_PAGE,
	HELP_CENTER_SEGMENT_PAGE,
];
