import { PureComponent } from 'react';
import { connect } from 'react-redux';
import { Route } from 'react-router';
import difference from 'lodash-es/difference';
import keys from 'lodash-es/keys';
import RingLeadLoadable from 'ringlead_utils/components/RingLeadLoadable/RingLeadLoadable';

const ForbiddenPage = RingLeadLoadable({
	loader: () => import('ringlead_utils/components/ForbiddenPage'),
});

@connect((state, ownProps) => {
	const { user } = state.auth;

	const isBeta = user.organization?.is_beta;
	const permissions = ownProps.permissions || [];
	const features = ownProps.features || [];

	if (user.is_staff) {
		return { isForbidden: false };
	}

	if (!isBeta && ownProps.isAdmin && !user.is_admin) {
		return { isForbidden: true };
	}

	if (isBeta && permissions.length > 0 && difference(keys(user.permissions), permissions).length === 0) {
		return { isForbidden: true };
	}

	if (features.length > 0 && difference(user.available_products, features).length === 0) {
		return { isForbidden: true };
	}

	return { isForbidden: false };
})
class SecureRoute extends PureComponent {
	render() {
		const { features, permissions, isForbidden, ...props } = this.props;

		if (isForbidden) {
			return <ForbiddenPage features={features} permissions={permissions} />;
		}

		return <Route {...props} />;
	}
}

export default SecureRoute;
