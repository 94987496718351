export const SegmentationMappingConstants = {
	SEGMENT_MAPPINGS_FETCHING: 'SEGMENT_MAPPINGS_FETCHING',
	SEGMENT_MAPPINGS_GET_SUCCESS: 'SEGMENT_MAPPINGS_GET_SUCCESS', // change to list success
	SEGMENT_MAPPINGS_GET_FAIL: 'SEGMENT_MAPPINGS_GET_FAIL',

	SEGMENT_MAPPINGS_CREATE_SUCCESS: 'SEGMENT_MAPPINGS_CREATE_SUCCESS',
	SEGMENT_MAPPINGS_CREATE_FAIL: 'SEGMENT_MAPPINGS_CREATE_FAIL',
	SEGMENT_MAPPINGS_UPDATE_SUCCESS: 'SEGMENT_MAPPINGS_UPDATE_SUCCESS',
	SEGMENT_MAPPINGS_UPDATE_FAIL: 'SEGMENT_MAPPINGS_UPDATE_FAIL',
	SEGMENT_MAPPINGS_DELETE_SUCCESS: 'SEGMENT_MAPPINGS_DELETE_SUCCESS',
	SEGMENT_MAPPINGS_DELETE_FAIL: 'SEGMENT_MAPPINGS_DELETE_FAIL',

	SEGMENT_MAPPINGS_ROW_CREATE_SUCCESS: 'SEGMENT_MAPPINGS_ROW_CREATE_SUCCESS',
	SEGMENT_MAPPINGS_ROW_CREATE_FAIL: 'SEGMENT_MAPPINGS_ROW_CREATE_FAIL',
	SEGMENT_MAPPINGS_ROW_UPDATE_SUCCESS: 'SEGMENT_MAPPINGS_ROW_UPDATE_SUCCESS',
	SEGMENT_MAPPINGS_ROW_UPDATE_FAIL: 'SEGMENT_MAPPINGS_ROW_UPDATE_FAIL',
};

export const SEGMENTS_MAX_LIMIT = 10;
