import includes from 'lodash-es/includes';
import { LOCATION_CHANGE } from 'redux-first-history';

import { EnrichmentConstants } from '../Constants';

const initialState = {
	enrichFields: [],
	fetchingEnrichFields: true,
	enrichmentMappings: [],
};

export default (state = initialState, action = {}) => {
	switch (action.type) {
		case LOCATION_CHANGE:
			if (includes(action.payload.location.pathname, 'resolution')) {
				return state;
			}

			return initialState;

		case EnrichmentConstants.TASK_ENRICH_FIELDS_GET_SUCCESS:
			return { ...state, enrichFields: action.data, fetchingEnrichFields: false };

		case EnrichmentConstants.TASK_ENRICH_FIELDS_GET_FAIL:
			return { ...state, enrichFields: [], fetchingEnrichFields: false };

		case EnrichmentConstants.TASK_ENRICHMENT_MAPPINGS_GET_SUCCESS:
			return { ...state, enrichmentMappings: action.data };

		case EnrichmentConstants.TASK_ENRICHMENT_MAPPINGS_GET_FAIL:
			return { ...state, enrichmentMappings: [] };

		case EnrichmentConstants.TASK_ENRICHMENT_MAPPINGS_UPDATE_SUCCESS:
			return { ...state, enrichmentMappings: action.data };

		case EnrichmentConstants.TASK_ENRICHMENT_MAPPINGS_UPDATE_FAIL:
			return { ...state, enrichmentMappings: [] };

		default:
			return state;
	}
};
