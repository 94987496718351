import includes from 'lodash-es/includes';
import { LOCATION_CHANGE } from 'redux-first-history';

import { SalesLoftConstants } from '../Constants';

const initialState = {
	cadences: [],
	fetching: true,
};

export default (state = initialState, action = {}) => {
	switch (action.type) {
		case LOCATION_CHANGE:
			return includes(action.payload.location.pathname, '/routing/workflows/') ? state : initialState;

		case SalesLoftConstants.LIST_CANDENCES_SUCCESS:
			return { ...state, fetching: false, cadences: action.data };

		case SalesLoftConstants.LIST_CANDENCES_FAIL:
			return { ...state, fetching: false, cadences: [] };

		default:
			return state;
	}
};
