import {
	type BaseQueryFn,
	type FetchArgs,
	fetchBaseQuery,
	type FetchBaseQueryError,
} from '@reduxjs/toolkit/query/react';
import NProgress from 'nprogress';

import { environment } from '../../../environments/environment';

import { paramsSerializer } from './paramsSerializer';
import { prepareHeaders } from './prepareHeaders';

const nprogress = {
	counter: 0,
	start() {
		this.counter += 1;
		if (!NProgress.isStarted()) {
			NProgress.start();
		}
	},
	complete() {
		this.counter -= 1;
		if (this.counter === 0) {
			NProgress.done();
		}
	},
};

const baseQuery = fetchBaseQuery({
	baseUrl: environment.apiBaseUrl,
	prepareHeaders,
	paramsSerializer,
});

const baseQueryWithNProgress: BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError> = async (
	args,
	api,
	extraOptions
) => {
	try {
		nprogress.start();
		const result = await baseQuery(args, api, extraOptions);

		nprogress.complete();
		return result;
	} catch (err) {
		nprogress.complete();
		throw err;
	}
};

export default baseQueryWithNProgress;
