export const urlBase64Decode = (str: string) => {
	let output = str.replace(/_/g, '/').replace(/-/g, '+');
	switch (output.length % 4) {
		case 0:
			break;
		case 2:
			output += '==';
			break;
		case 3:
			output += '=';
			break;
		default:
			throw new Error('Illegal base64url string!');
	}
	return decodeURIComponent(escape(window.atob(output))); // polyfill https://github.com/davidchambers/Base64.js
};

export const decodeToken = (token: string) => {
	const parts = token.split('.');

	if (parts.length !== 3) {
		throw new Error('JWT must have 3 parts');
	}

	const decoded = urlBase64Decode(parts[1]);

	if (!decoded) {
		throw new Error('Cannot decode the token');
	}

	return JSON.parse(decoded);
};
