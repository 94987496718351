import { RULE_SEARCH_ACTIVE, RULE_SEARCH_RESET } from 'app/routing/Constants';
import { LOCATION_CHANGE } from 'redux-first-history';

const initialState = {
	message: '',
	searching: false,
	allMatchedRules: [],
};

export default (state = initialState, action = {}) => {
	switch (action.type) {
		case LOCATION_CHANGE:
			return initialState;

		case RULE_SEARCH_ACTIVE:
			return { ...state, ...action.data };

		case RULE_SEARCH_RESET:
			return { ...state, ...initialState };

		default:
			return state;
	}
};
