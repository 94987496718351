import type { User } from 'app/auth/types/user';

import { environment } from '../../../environments/environment';

export const initPendo = (user: User) => {
	pendo.initialize({
		visitor: {
			id: `${environment.serverName}-${user.id}`, // Required if user is logged in
			email: user.email, // Recommended if using Pendo Feedback, or NPS Email
			firstName: user.first_name,
			lastName: user.last_name,
			mobileNumber: user.mobile_number,
			companyName: user.company,
			isActive: true,
			isAdmin: user.is_admin,
			isStaff: user.is_staff,
			isMasked: user.is_masked,
			isFirstLogin: user.is_first_login,
		},
		account: user.organization
			? {
					id: `${environment.serverName}-${user.organization?.id}`,
					name: user.organization.name,
					timezone: user.organization.timezone,
				}
			: {
					id: `${environment.serverName}-NO_ORG`,
				},
	});
};
