import pickBy from 'lodash-es/pickBy';

import { TaskFiltersConstants } from '../Constants';

const initialState = {
	fetching: {},
	count: {},
	error: {},
};

export default (state = initialState, action = {}) => {
	switch (action.type) {
		case TaskFiltersConstants.COUNT_RECORDS_FILTER:
			return {
				...state,
				fetching: { ...state.fetching, [action.objectName]: true },
				count: pickBy(state.count, (v, k) => k !== action.objectName),
				error: pickBy(state.error, (v, k) => k !== action.objectName),
			};

		case TaskFiltersConstants.COUNT_RECORDS_FILTER_SUCCESS:
			return {
				...state,
				fetching: pickBy(state.fetching, (v, k) => k !== action.objectName),
				count: { ...state.count, [action.objectName]: action.data.count },
				error: action.data.error
					? { ...state.error, [action.objectName]: action.data.error }
					: pickBy(state.error, (v, k) => k !== action.objectName),
			};

		case TaskFiltersConstants.COUNT_RECORDS_FILTER_FAIL:
			return {
				...state,
				fetching: pickBy(state.fetching, (v, k) => k !== action.objectName),
				count: pickBy(state.count, (v, k) => k !== action.objectName),
				error: { ...state.error, [action.error]: action.data || {} },
			};

		default:
			return state;
	}
};
