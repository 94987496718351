import { datadogRum } from '@datadog/browser-rum';

import { environment } from '../../../environments/environment';

const SERVER_NAME_DD_ENV_MAP: Record<string, string> = {
	dev: 'development',
	release: 'production',
};
const DD_ENV = SERVER_NAME_DD_ENV_MAP[environment.serverName] || environment.serverName;

export const initDataDog = () => {
	if (environment.production) {
		datadogRum.init({
			clientToken: 'pub0f8456fef10f44e31695407cb8ad4985',
			applicationId: '9e6e1c47-61c1-4572-8fa3-25b71b40a577',
			site: 'datadoghq.com',
			service: 'dms',
			env: DD_ENV,
			// Specify a version number to identify the deployed version of your application in Datadog
			// version: '1.0.0',
			sessionSampleRate: 100,
			sessionReplaySampleRate: 0,
			trackUserInteractions: true,
			trackResources: true,
			trackLongTasks: true,
			defaultPrivacyLevel: 'mask-user-input',
		});
	}
};
