import { ModalConstants } from '../Constants';

const initialState = {};

export default (state = initialState, action = {}) => {
	if (action.type === ModalConstants.DO_NOT_SHOW) {
		return { ...state, [action.key]: true };
	}

	return state;
};
