import forEach from 'lodash-es/forEach';
import map from 'lodash-es/map';
import reject from 'lodash-es/reject';
import sortBy from 'lodash-es/sortBy';
import split from 'lodash-es/split';
import trim from 'lodash-es/trim';

export const createBooleanString = conditions => {
	let booleanString = '';

	forEach(sortBy(conditions, 'order'), condition => {
		if (condition.operator === 'DIVIDER') {
			booleanString = `${booleanString.slice(0, -5)} OR `;
		} else {
			booleanString += `${condition.field} ${condition.operator} "${condition.field_value}" AND `;
		}
	});

	return booleanString.slice(0, -4);
};

export const createFiltersBooleanLogic = (filters, parentCompany) => {
	const ids = parentCompany ? 'tmpId' : 'id';
	const filterIds = map(reject(filters, ['operator', 'DIVIDER']), ids);
	const sortedFilters = sortBy(filters, 'order');

	let booleanString = '';

	forEach(sortedFilters, (f, i) => {
		if (f.operator === 'DIVIDER') {
			return;
		}

		const filterIndex = filterIds.indexOf(parentCompany ? f.tmpId : f.id) + 1;

		if (!booleanString) {
			booleanString += filterIndex.toString();
		} else {
			booleanString += `${
				sortedFilters[i - 1].operator === 'DIVIDER' ? ' OR' : ' AND'
			} ${filterIndex.toString()}`;
		}
	});

	return booleanString;
};

export const createAdvancedBooleanString = (filters, booleanLogic) => {
	const filtersArray = map(reject(filters, ['operator', 'DIVIDER']));
	let booleanString = '';

	forEach(split(booleanLogic, ' '), f => {
		if (Number.isFinite(+f)) {
			const condition = filtersArray[+f - 1];
			booleanString += ` ${condition.field} ${condition.operator} "${condition.field_value}"`;
		} else {
			booleanString += ` ${f}`;
		}
	});
	return trim(booleanString);
};

export const isValid = f =>
	f.operator === 'DIVIDER' ||
	(f.field && f.operator && (('allowsValue' in f.operator && !f.operator.allowsValue) || f.field_value));
