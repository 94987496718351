import { VisibilitySensorConstants } from '../Constants';

const initialState = {
	width: window.innerWidth || document.documentElement.clientWidth,
	height: window.innerHeight || document.documentElement.clientHeight,
};

export default (state = initialState, action = {}) => {
	if (action.type === VisibilitySensorConstants.SCROLLING) {
		return {
			width: window.innerWidth || document.documentElement.clientWidth,
			height: window.innerHeight || document.documentElement.clientHeight,
		};
	}

	return state;
};
