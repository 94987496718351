import reduce from 'lodash-es/reduce';

import { LicensingConstants } from '../Constants';

const initialState = {
	features: {},
	fetchingFeatures: true,
};

export default (state = initialState, action = {}) => {
	switch (action.type) {
		case LicensingConstants.FEATURES_LIST_SUCCESS:
			return {
				...state,
				features: reduce(action.data, (result, feature) => ({ ...result, [feature.name]: feature.label }), {}),
				fetchingFeatures: false,
			};

		case LicensingConstants.FEATURES_LIST_FAIL:
			return { ...state, features: {}, fetchingFeatures: false };

		default:
			return state;
	}
};
