export const ConnectionTypes = Object.freeze({
	SALESFORCE: 'salesforce',
	MARKETO: 'marketo',
	PARDOT: 'pardot',
	ELOQUA: 'eloqua',
	HUBSPOT: 'hubspot',
	DYNAMICS: 'dynamics',
	SNOWFLAKE: 'snowflake',
} as const);

export const ConnectionStatuses = Object.freeze({
	CREATED: 'CREA',
	ACTIVE: 'ACTIVE',
	PENDING: 'PENDING',
	REFRESH: 'REFRESH',
	REFRESH_FAIL: 'REFRESH_FAIL',
	REMOVING: 'REMOVING',
} as const);
