import { createSlice } from '@reduxjs/toolkit';

type FolderState = {
	editFolderId: number | null;
};

const folderSlice = createSlice({
	name: 'folder',
	initialState: { editFolderId: null } as FolderState,
	reducers: {
		setEditFolderId: (state, action) => {
			state.editFolderId = action.payload;
		},
	},
});

export const { setEditFolderId } = folderSlice.actions;

export default folderSlice.reducer;
