import { createApi } from '@reduxjs/toolkit/dist/query/react';
import baseQueryWithNProgress from 'app/utils/services/baseQueryWithNProgress';

import type { PackageField } from '../types/packageTypes';

export const packageApi = createApi({
	reducerPath: 'packageApi',
	baseQuery: baseQueryWithNProgress,
	endpoints: builder => ({
		getAllPackageFields: builder.query<PackageField[], number>({
			query: packageId => `/data_exchange/packages/${packageId}/fields/`,
			keepUnusedDataFor: 0
		}),
		updateAllPackageFields: builder.mutation<
			PackageField[],
			{ packageId: number; body: { id: number; priority: number; group?: string }[] }
		>({
			query: ({ packageId, body }) => ({
				url: `/data_exchange/packages/${packageId}/fields/`,
				method: 'PATCH',
				body,
			}),
			async onQueryStarted({ packageId }, { dispatch, queryFulfilled }) {
				try {
					const { data } = await queryFulfilled;
					dispatch(packageApi.util.updateQueryData('getAllPackageFields', packageId, () => data));
				} catch {
					/* empty */
				}
			},
		}),
	}),
});

export const { useGetAllPackageFieldsQuery, useUpdateAllPackageFieldsMutation } = packageApi;
