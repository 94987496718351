import includes from 'lodash-es/includes';
import { LOCATION_CHANGE } from 'redux-first-history';

import { ConnectionTablesConstants } from '../Constants';

const initialState = {
	tables: [],
	fetching: true,
	childObjects: [],
	fetchingChildObjects: true,
	parentObjects: [],
	fetchingParentObjects: true,
};

export default (state = initialState, action = {}) => {
	switch (action.type) {
		case LOCATION_CHANGE:
			if (
				(includes(action.payload.location.pathname, 'objects') ||
					includes(action.payload.location.pathname, 'connections') ||
					includes(action.payload.location.pathname, 'resolution') ||
					(includes(action.payload.location.pathname, '/routing/workflows/') &&
						action.payload.location.pathname !== '/routing/workflows/')) &&
				!includes(action.payload.location.pathname, 'auth')
			) {
				return state;
			}

			return initialState;

		case ConnectionTablesConstants.TABLE_LIST:
			return { ...state, tables: [], fetching: true };

		case ConnectionTablesConstants.TABLE_LIST_SUCCESS:
			return { ...state, tables: action.data, fetching: false };

		case ConnectionTablesConstants.TABLE_LIST_FAIL:
			return { ...state, tables: [], fetching: false };

		case ConnectionTablesConstants.TABLE_CHILD_LIST:
			return { ...state, fetchingChildObjects: true };

		case ConnectionTablesConstants.TABLE_CHILD_LIST_SUCCESS:
			return { ...state, childObjects: action.data, fetchingChildObjects: false };

		case ConnectionTablesConstants.TABLE_CHILD_LIST_FAIL:
			return { ...state, childObjects: [], fetchingChildObjects: false };

		case ConnectionTablesConstants.TABLE_PARENT_LIST:
			return { ...state, fetchingParentObjects: true };

		case ConnectionTablesConstants.TABLE_PARENT_LIST_SUCCESS:
			return { ...state, parentObjects: action.data, fetchingParentObjects: false };

		case ConnectionTablesConstants.TABLE_PARENT_LIST_FAIL:
			return { ...state, parentObjects: [], fetchingParentObjects: false };

		default:
			return state;
	}
};
