import map from 'lodash-es/map';

import { FlowChartPopupConstants } from '../Constants';

const initialState = {
	workflowId: null,
	matchType: null,
	fetching: true,
	routingFlowData: null,
	selectedRule: null,
	cellType: null,
	cellId: null,
};

export default (state = initialState, action = {}) => {
	switch (action.type) {
		case FlowChartPopupConstants.POPUP_SHOW:
			return { ...state, workflowId: action.workflowId, matchType: action.matchType, fetching: true };

		case FlowChartPopupConstants.POPUP_CLOSE:
			return initialState;

		case FlowChartPopupConstants.ROUTING_FLOW_DATA_SUCCESS:
			return { ...state, routingFlowData: action.data, fetching: false };

		case FlowChartPopupConstants.ROUTING_FLOW_DATA_FAIL:
			return { ...state, routingFlowData: null, fetching: false };

		case FlowChartPopupConstants.CHANGE_ELEMENT_SELECTION:
			return { ...state, selectedRule: action.elementId, cellType: action.cellType, cellId: action.id };

		case FlowChartPopupConstants.CHANGE_MATCH_TYPE:
			return { ...state, matchType: action.matchType, selectedRule: null, cellType: null, cellId: null };

		case FlowChartPopupConstants.ROUTING_FLOW_DATA_UPDATE_SUCCESS:
			return {
				...state,
				routingFlowData: {
					cells: map(state.routingFlowData.cells, cell =>
						cell.id === action.data.id ? { ...cell, attrs: { ...cell.attrs, ...action.data.attrs } } : cell
					),
				},
			};

		case FlowChartPopupConstants.ROUTING_FLOW_DATA_UPDATE_FAIL:
			return { ...state, routingFlowData: null };

		default:
			return state;
	}
};
