import type { Middleware } from '@reduxjs/toolkit';
import { authApi } from 'app/auth/services/auth';
import { decodeToken } from 'app/auth/utils/jwt';

const jwtMiddleware: Middleware =
	({ dispatch, getState }) =>
	next =>
	action => {
		const { token, user, tokenIsRefreshing } = getState().auth;
		if (action.meta?.arg?.endpointName !== 'refreshToken' && token && !tokenIsRefreshing) {
			const encodedToken = decodeToken(token);

			if (
				(encodedToken.exp &&
					Math.floor((encodedToken.exp - new Date().getTime() / 1000) / 60) <=
						encodedToken.session_timeout / 4) ||
				!user
			) {
				// eslint-disable-next-line @typescript-eslint/ban-ts-comment
				// @ts-ignore
				dispatch(authApi.endpoints.refreshToken.initiate());
			}
		}
		return next(action);
	};

export default jwtMiddleware;
