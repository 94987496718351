import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';

export type Breadcrumb =
	| {
			label: string;
			url: string;
	  }
	| string;

interface BreadcrumbsState {
	breadcrumbs: Breadcrumb[];
}

const breadcrumbsSlice = createSlice({
	name: 'breadcrumbs',
	initialState: { breadcrumbs: [] } as BreadcrumbsState,
	reducers: {
		setBreadcrumbs: (state: BreadcrumbsState, action: PayloadAction<Breadcrumb[]>) => {
			state.breadcrumbs = action.payload;
		},
	},
});

export const { setBreadcrumbs } = breadcrumbsSlice.actions;

export default breadcrumbsSlice.reducer;
