import concat from 'lodash-es/concat';
import filter from 'lodash-es/filter';
import find from 'lodash-es/find';
import forEach from 'lodash-es/forEach';
import includes from 'lodash-es/includes';
import invokeMap from 'lodash-es/invokeMap';
import map from 'lodash-es/map';
import pickBy from 'lodash-es/pickBy';
import reduce from 'lodash-es/reduce';
import { LOCATION_CHANGE } from 'redux-first-history';

import { MasterRecordRulesConstants } from '../Constants';

const initialState = {
	masterRecordRules: [],
	fetching: true,
	errors: {},
};

export default (state = initialState, action = {}) => {
	switch (action.type) {
		case LOCATION_CHANGE:
			return initialState;

		case MasterRecordRulesConstants.LOAD_MASTER_RECORD_RULES_SUCCESS:
			return { ...state, masterRecordRules: action.data, fetching: false };

		case MasterRecordRulesConstants.LOAD_MASTER_RECORD_RULES_FAIL:
			return { ...state, masterRecordRules: [], fetching: false };

		case MasterRecordRulesConstants.CREATE_MASTER_RECORD_RULE_SUCCESS: {
			let { masterRecordRules } = state;

			forEach(action.rules, (rule, index) => {
				masterRecordRules = find(masterRecordRules, ['tmpId', rule.tmpId])
					? map(masterRecordRules, masterRecordRule =>
							masterRecordRule.tmpId === rule.tmpId
								? { ...masterRecordRule, ...action.data[index] }
								: masterRecordRule
						)
					: concat([], masterRecordRules, action.data[index]);
			});

			return {
				...state,
				masterRecordRules,
				errors: pickBy(state.errors, (_v, k) => !includes(map(action.rules, 'tmpId'), k)),
			};
		}

		case MasterRecordRulesConstants.CREATE_MASTER_RECORD_RULE_FAIL: {
			let { masterRecordRules } = state;

			forEach(action.rules, rule => {
				masterRecordRules = includes(map(masterRecordRules, 'tmpId'), rule.tmpId)
					? map(masterRecordRules, masterRecordRule => {
							const actionRule = find(action.rules, ['tmpId', masterRecordRule.tmpId]);

							return actionRule ? { ...masterRecordRule, ...actionRule } : masterRecordRule;
						})
					: concat([], masterRecordRules, rule);
			});

			return {
				...state,
				masterRecordRules,
				errors: {
					...state.errors,
					...reduce(
						action.rules,
						(result, rule, index) => ({ ...result, [rule.tmpId]: action.data[index] || {} }),
						{}
					),
				},
			};
		}

		case MasterRecordRulesConstants.PATCH_MASTER_RECORD_RULE_SUCCESS: {
			const allMasterRecordRulesIds = map(action.rules, rule => rule.id.toString());

			return {
				...state,
				masterRecordRules: map(state.masterRecordRules, masterRecordRule => {
					const actionRule = find(action.data, ['id', masterRecordRule.id]);

					return actionRule ? { ...masterRecordRule, ...actionRule } : masterRecordRule;
				}),
				errors: pickBy(state.errors, (_v, k) => !includes(allMasterRecordRulesIds, k)),
			};
		}

		case MasterRecordRulesConstants.PATCH_MASTER_RECORD_RULE_FAIL:
			return {
				...state,
				masterRecordRules: map(state.masterRecordRules, masterRecordRule => {
					const actionRule = find(action.rules, ['id', masterRecordRule.id]);

					return actionRule ? { ...masterRecordRule, ...actionRule } : masterRecordRule;
				}),
				errors: {
					...state.errors,
					...reduce(
						action.rules,
						(result, rule, index) => ({ ...result, [rule.id]: action.data[index] || {} }),
						{}
					),
				},
			};

		case MasterRecordRulesConstants.DELETE_MASTER_RECORD_RULE_SUCCESS: {
			const allMasterRecordRulesIds = invokeMap(action.ids, 'toString');

			return {
				...state,
				masterRecordRules: filter(
					state.masterRecordRules,
					masterRecordRule =>
						!includes(action.ids, masterRecordRule.id) && !includes(action.ids, masterRecordRule.tmpId)
				),
				errors: pickBy(state.errors, (_v, k) => !includes(allMasterRecordRulesIds, k)),
			};
		}

		case MasterRecordRulesConstants.DELETE_MASTER_RECORD_RULE_FAIL:
			return state;

		default:
			return state;
	}
};
