import concat from 'lodash-es/concat';
import filter from 'lodash-es/filter';
import map from 'lodash-es/map';
import pickBy from 'lodash-es/pickBy';
import { LOCATION_CHANGE } from 'redux-first-history';

import { TaskConfigConstants } from '../Constants';

const initialState = {
	taskConfigs: [],
	fetching: true,
};

export default (state = initialState, action = {}) => {
	switch (action.type) {
		case LOCATION_CHANGE:
			return initialState;

		case TaskConfigConstants.TASK_CONFIG_LIST_SUCCESS:
			return { ...state, taskConfigs: action.data, fetching: false };

		case TaskConfigConstants.TASK_CONFIG_LIST_FAIL:
			return { ...state, taskConfigs: [], fetching: false };

		case TaskConfigConstants.TASK_CONFIG_CREATE_SUCCESS:
			return {
				...state,
				taskConfigs: concat([], state.taskConfigs, action.data),
			};

		case TaskConfigConstants.TASK_CONFIG_CREATE_FAIL:
			return state;

		case TaskConfigConstants.TASK_CONFIG_UPDATE_SUCCESS:
			return {
				...state,
				taskConfigs: map(state.taskConfigs, taskConfig =>
					taskConfig.id === action.task_config.id
						? { ...pickBy(taskConfig, (_v, k) => k === 'is_new'), ...action.data }
						: taskConfig
				),
			};

		case TaskConfigConstants.TASK_CONFIG_UPDATE_FAIL:
			return state;

		case TaskConfigConstants.TASK_CONFIG_DELETE_SUCCESS:
			return {
				...state,
				taskConfigs: filter(
					state.taskConfigs,
					taskConfig => taskConfig.id !== action.taskConfigId && taskConfig.tmpId !== action.taskConfigId
				),
			};

		case TaskConfigConstants.TASK_CONFIG_DELETE_FAIL:
			return state;

		default:
			return state;
	}
};
