import concat from 'lodash-es/concat';
import filter from 'lodash-es/filter';
import find from 'lodash-es/find';
import forEach from 'lodash-es/forEach';
import includes from 'lodash-es/includes';
import map from 'lodash-es/map';
import pickBy from 'lodash-es/pickBy';
import reduce from 'lodash-es/reduce';
import { LOCATION_CHANGE } from 'redux-first-history';

import { TaskFiltersConstants } from '../Constants';

const initialState = {
	filters: [],
	fetching: true,
	errors: {},
};

export default (state = initialState, action = {}) => {
	switch (action.type) {
		case LOCATION_CHANGE:
			return initialState;

		case TaskFiltersConstants.LIST_FILTER_SUCCESS:
			return { ...state, filters: action.data, fetching: false };

		case TaskFiltersConstants.LIST_FILTER_FAIL:
			return { ...state, filters: [], fetching: false };

		case TaskFiltersConstants.CREATE_FILTER_SUCCESS:
			return {
				...state,
				filters: find(state.filters, ['tmpId', action.filter.tmpId])
					? map(state.filters, recordFilter =>
							recordFilter.tmpId === action.filter.tmpId
								? { ...recordFilter, ...action.data }
								: recordFilter
						)
					: concat([], state.filters, action.data),
				errors: pickBy(state.errors, (_v, k) => k !== action.filter.tmpId),
			};

		case TaskFiltersConstants.CREATE_FILTER_FAIL:
			let { filters } = state;

			forEach(action.filters, ff => {
				filters = includes(map(filters, 'tmpId'), ff.tmpId)
					? map(filters, f => (f.tmpId === ff.tmpId ? { ...f, ...ff } : f))
					: concat([], filters, ff);
			});

			return {
				...state,
				filters,
				errors: {
					...state.errors,
					...reduce(
						action.filters,
						(result, ff, index) => ({ ...result, [ff.tmpId]: action.data[index] || {} }),
						{}
					),
				},
			};

		case TaskFiltersConstants.UPDATE_FILTER_SUCCESS:
			const allRecordFilterIds = map(action.filters, ff => ff.id.toString());

			return {
				...state,
				filters: map(state.filters, ff => {
					const actionFilter = find(action.data, ['id', ff.id]);
					return actionFilter ? { ...ff, ...actionFilter } : ff;
				}),
				errors: pickBy(state.errors, (_v, k) => !includes(allRecordFilterIds, k)),
			};

		case TaskFiltersConstants.UPDATE_FILTER_FAIL:
			return {
				...state,
				filters: map(state.filters, ff => {
					const actionFilter = find(action.filters, ['id', ff.id]);
					return actionFilter ? { ...ff, ...actionFilter } : ff;
				}),
				errors: {
					...state.errors,
					...reduce(
						action.filters,
						(result, ff, index) => ({ ...result, [ff.id]: action.data[index] || {} }),
						{}
					),
				},
			};

		case TaskFiltersConstants.DELETE_FILTER_SUCCESS:
			return {
				...state,
				filters: filter(state.filters, f => f.id !== action.pk && f.tmpId !== action.pk),
				errors: pickBy(state.errors, (_v, k) => k !== action.pk.toString()),
			};

		case TaskFiltersConstants.DELETE_FILTER_FAIL:
			return state;

		default:
			return state;
	}
};
