import { EventsConstants } from 'app/dop/Constants';
import map from 'lodash-es/map';
import reject from 'lodash-es/reject';

const initialState = {
	fetchingEvents: true,
	events: [],
	fetchingEventSources: true,
	eventSources: [],
	fetchingEventActionTypes: true,
	eventActionTypes: [],
	fetchingEventNotificationTypes: true,
	eventNotificationTypes: [],
	fetchingEventCreateRecordTypes: true,
	eventCreateRecordTypes: [],
	fetchingTriggers: false,
	triggers: [],
	dynamicFields: [],
	webSubmissionTasks: [],
	fetchingWebSubmissionTasks: true,
	listImportTasks: [],
	fetchingListImportTasks: true,
};

export default (state = initialState, action = {}) => {
	// eslint-disable-next-line sonarjs/max-switch-cases
	switch (action.type) {
		case EventsConstants.GET_TASK_EVENTS:
			return { ...state, fetchingEvents: true };

		case EventsConstants.GET_TASK_EVENTS_SUCCESS:
			return { ...state, fetchingEvents: false, events: action.data };

		case EventsConstants.GET_TASK_EVENTS_FAIL:
			return { ...state, fetchingEvents: false, events: [] };

		case EventsConstants.CREATE_EVENT_SUCCESS:
		case EventsConstants.COPY_EVENT_SUCCESS:
			return { ...state, events: [...state.events, action.data] };

		case EventsConstants.UPDATE_EVENT_SUCCESS:
			return {
				...state,
				events: map(state.events, event => {
					if (event.id === action.data.id) {
						return action.data;
					}

					return event;
				}),
			};

		case EventsConstants.DELETE_EVENT_SUCCESS:
			return { ...state, events: reject(state.events, ['id', action.eventId]) };

		case EventsConstants.DELETE_EVENTS_SUCCESS:
			return { ...state, events: reject(state.events, event => action.ids.includes(event.id)) };

		case EventsConstants.GET_EVENT_SOURCES:
			return { ...state, fetchingEventSources: true };

		case EventsConstants.GET_EVENT_SOURCES_SUCCESS:
			return { ...state, fetchingEventSources: false, eventSources: action.data };

		case EventsConstants.GET_EVENT_SOURCES_FAIL:
			return { ...state, fetchingEventSources: false, eventSources: [] };

		case EventsConstants.GET_EVENT_ACTION_TYPES:
			return { ...state, fetchingEventActionTypes: true };

		case EventsConstants.GET_EVENT_ACTION_TYPES_SUCCESS:
			return { ...state, fetchingEventActionTypes: false, eventActionTypes: action.data };

		case EventsConstants.GET_EVENT_ACTION_TYPES_FAIL:
			return { ...state, fetchingEventActionTypes: false, eventActionTypes: [] };

		case EventsConstants.GET_EVENT_NOTIFICATION_TYPES:
			return { ...state, fetchingEventNotificationTypes: true };

		case EventsConstants.GET_EVENT_NOTIFICATION_TYPES_SUCCESS:
			return { ...state, fetchingEventNotificationTypes: false, eventNotificationTypes: action.data };

		case EventsConstants.GET_EVENT_NOTIFICATION_TYPES_FAIL:
			return { ...state, fetchingEventNotificationTypes: false, eventNotificationTypes: [] };

		case EventsConstants.GET_EVENT_CREATE_RECORD_TYPES:
			return { ...state, fetchingEventCreateRecordTypes: true };

		case EventsConstants.GET_EVENT_CREATE_RECORD_TYPES_SUCCESS:
			return { ...state, fetchingEventCreateRecordTypes: false, eventCreateRecordTypes: action.data };

		case EventsConstants.GET_EVENT_CREATE_RECORD_TYPES_FAIL:
			return { ...state, fetchingEventCreateRecordTypes: false, eventCreateRecordTypes: [] };

		case EventsConstants.GET_EVENT_TRIGGERS:
			return { ...state, fetchingTriggers: true, triggers: [] };

		case EventsConstants.GET_EVENT_TRIGGERS_SUCCESS:
			return { ...state, fetchingTriggers: false, triggers: action.data };

		case EventsConstants.GET_EVENT_TRIGGERS_FAIL:
			return { ...state, fetchingTriggers: false, triggers: [] };

		case EventsConstants.GET_EVENT_DYNAMIC_FIELDS_SUCCESS:
			return { ...state, dynamicFields: action.data };

		case EventsConstants.GET_EVENT_DYNAMIC_FIELDS_FAIL:
			return { ...state, dynamicFields: [] };

		case EventsConstants.GET_EVENT_WEBSUBMISSION_TASKS:
			return { ...state, fetchingWebSubmissionTasks: true, webSubmissionTasks: [] };

		case EventsConstants.GET_EVENT_WEBSUBMISSION_TASKS_SUCCESS:
			return { ...state, fetchingWebSubmissionTasks: false, webSubmissionTasks: action.data };

		case EventsConstants.GET_EVENT_WEBSUBMISSION_TASKS_FAIL:
			return { ...state, fetchingWebSubmissionTasks: false, webSubmissionTasks: [] };

		case EventsConstants.GET_EVENT_LIST_IMPORT_TASKS:
			return { ...state, fetchingListImportTasks: true, listImportTasks: [] };

		case EventsConstants.GET_EVENT_LIST_IMPORT_TASKS_SUCCESS:
			return { ...state, fetchingListImportTasks: false, listImportTasks: action.data };

		case EventsConstants.GET_EVENT_LIST_IMPORT_TASKS_FAIL:
			return { ...state, fetchingListImportTasks: false, listImportTasks: null };

		case EventsConstants.USE_EVENTS_TEMPLATE_SUCCESS:
			return { ...state, events: action.data };

		case EventsConstants.CREATE_EVENT_FAIL:
		case EventsConstants.COPY_EVENT_FAIL:
		case EventsConstants.UPDATE_EVENT_FAIL:
		case EventsConstants.DELETE_EVENT_FAIL:
		case EventsConstants.DELETE_EVENTS_FAIL:
			return { ...state };

		default:
			return state;
	}
};
