import cookie from 'app/auth/utils/cookie';

import { environment } from '../../../environments/environment';

export const prepareHeaders = (headers: Headers) => {
	const token = cookie.get(`jwtToken${environment.serverName}`);
	if (token) {
		headers.set('Authorization', `JWT ${token}`);
	}
	return headers;
};
