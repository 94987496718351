import { createApi } from '@reduxjs/toolkit/dist/query/react';
import baseQueryWithNProgress from 'app/utils/services/baseQueryWithNProgress';

import type { DiscoverySearchOutputField } from '../types/discoveryTypes';

export const discoveryApi = createApi({
	reducerPath: 'discoveryApi',
	baseQuery: baseQueryWithNProgress,
	endpoints: builder => ({
		getDiscoverySearchOutputFields: builder.query<DiscoverySearchOutputField[], number>({
			query: taskId => `discovery/${taskId}/output_fields/`,
		}),
	}),
});

export const { useGetDiscoverySearchOutputFieldsQuery } = discoveryApi;
