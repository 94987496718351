import { createApi } from '@reduxjs/toolkit/dist/query/react';

import baseQueryWithNProgress from './baseQueryWithNProgress';

interface Configuration {
	authorization_types: string[];
	beta_features: string[];
}

export const commonApi = createApi({
	reducerPath: 'api',
	baseQuery: baseQueryWithNProgress,
	endpoints: builder => ({
		getConfiguration: builder.query<Configuration, void>({
			query: () => '/configuration/',
		}),
	}),
});

export const { useGetConfigurationQuery } = commonApi;
