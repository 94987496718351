import { createApi } from '@reduxjs/toolkit/dist/query/react';
import baseQueryWithNProgress from 'app/utils/services/baseQueryWithNProgress';

import type { Storage } from '../types/storage';

export const storageApi = createApi({
	reducerPath: 'storageApi',
	baseQuery: baseQueryWithNProgress,
	endpoints: builder => ({
		getStorages: builder.query<Storage[], void>({
			query: () => '/storage/',
		}),
	}),
});

export const { useGetStoragesQuery } = storageApi;
