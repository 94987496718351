export const CustomPackagesConstants = {
	CUSTOM_PACKAGES_LIST_SUCCESS: 'CUSTOM_PACKAGES_LIST_SUCCESS',
	CUSTOM_PACKAGES_LIST_FAIL: 'CUSTOM_PACKAGES_LIST_FAIL',
	CUSTOM_PACKAGE_RETRIEVE_SUCCESS: 'CUSTOM_PACKAGE_RETRIEVE_SUCCESS',
	CUSTOM_PACKAGE_RETRIEVE_FAIL: 'CUSTOM_PACKAGE_RETRIEVE_FAIL',
	CUSTOM_PACKAGE_CREATE_SUCCESS: 'CUSTOM_PACKAGE_CREATE_SUCCESS',
	CUSTOM_PACKAGE_CREATE_FAIL: 'CUSTOM_PACKAGE_CREATE_FAIL',
	CUSTOM_PACKAGE_COPY_SUCCESS: 'CUSTOM_PACKAGE_COPY_SUCCESS',
	CUSTOM_PACKAGE_COPY_FAIL: 'CUSTOM_PACKAGE_COPY_FAIL',
	CUSTOM_PACKAGE_UPDATE: 'CUSTOM_PACKAGE_UPDATE',
	CUSTOM_PACKAGE_UPDATE_SUCCESS: 'CUSTOM_PACKAGE_UPDATE_SUCCESS',
	CUSTOM_PACKAGE_UPDATE_FAIL: 'CUSTOM_PACKAGE_UPDATE_FAIL',
	CUSTOM_PACKAGE_DELETE_SUCCESS: 'CUSTOM_PACKAGE_DELETE_SUCCESS',
	CUSTOM_PACKAGE_DELETE_FAIL: 'CUSTOM_PACKAGE_DELETE_FAIL',
	CUSTOM_PACKAGE_VENDOR_API_UPDATE_SUCCESS: 'CUSTOM_PACKAGE_VENDOR_API_UPDATE_SUCCESS',
	CUSTOM_PACKAGE_RESET_WARNINGS: 'CUSTOM_PACKAGE_RESET_WARNINGS',
	CUSTOM_PACKAGE_RESET_ERROR: 'CUSTOM_PACKAGE_RESET_ERROR',
	ENRICHMENT_FIELDS_GET_SUCCESS: 'ENRICHMENT_FIELDS_GET_SUCCESS',
	ENRICHMENT_FIELDS_GET_FAIL: 'ENRICHMENT_FIELDS_GET_FAIL',
	ENRICHMENT_FIELD_CREATE_SUCCESS: 'ENRICHMENT_FIELD_CREATE_SUCCESS',
	ENRICHMENT_FIELD_CREATE_FAIL: 'ENRICHMENT_FIELD_CREATE_FAIL',
};

export const CustomPackageVendorConstants = {
	PACKAGE_VENDOR_API_GET: 'PACKAGE_VENDOR_API_GET',
	PACKAGE_VENDOR_API_GET_SUCCESS: 'PACKAGE_VENDOR_API_GET_SUCCESS',
	PACKAGE_VENDOR_API_GET_FAIL: 'PACKAGE_VENDOR_API_GET_FAIL',
	PACKAGE_VENDOR_API_UPDATE: 'PACKAGE_VENDOR_API_UPDATE',
	PACKAGE_VENDOR_API_UPDATE_SUCCESS: 'PACKAGE_VENDOR_API_UPDATE_SUCCESS',
	PACKAGE_VENDOR_API_UPDATE_FAIL: 'PACKAGE_VENDOR_API_UPDATE_FAIL',
	PACKAGE_VENDOR_API_LIVE_QUERY_GET: 'PACKAGE_VENDOR_API_LIVE_QUERY_GET',
	PACKAGE_VENDOR_API_LIVE_QUERY_GET_SUCCESS: 'PACKAGE_VENDOR_API_LIVE_QUERY_GET_SUCCESS',
	PACKAGE_VENDOR_API_LIVE_QUERY_GET_FAIL: 'PACKAGE_VENDOR_API_LIVE_QUERY_GET_FAIL',
	PACKAGE_VENDOR_API_LIVE_SAMPLE_GET: 'PACKAGE_VENDOR_API_LIVE_SAMPLE_GET',
	PACKAGE_VENDOR_API_LIVE_SAMPLE_GET_SUCCESS: 'PACKAGE_VENDOR_API_LIVE_SAMPLE_GET_SUCCESS',
	PACKAGE_VENDOR_API_LIVE_SAMPLE_GET_FAIL: 'PACKAGE_VENDOR_API_LIVE_SAMPLE_GET_FAIL',
	PACKAGE_VENDOR_API_GUESS_ENRICH_ELEMENT_GET_SUCCESS: 'PACKAGE_VENDOR_API_GUESS_ENRICH_ELEMENT_GET_SUCCESS',
	PACKAGE_VENDOR_API_GUESS_ENRICH_ELEMENT_GET_FAIL: 'PACKAGE_VENDOR_API_GUESS_ENRICH_ELEMENT_GET_FAIL',
	PACKAGE_VENDOR_API_SAMPLE_GET_SUCCESS: 'PACKAGE_VENDOR_API_SAMPLE_GET_SUCCESS',
	PACKAGE_VENDOR_API_SAMPLE_GET_FAIL: 'PACKAGE_VENDOR_API_SAMPLE_GET_FAIL',
};

export const CustomPackageVendorFieldsConstants = {
	VENDOR_API_FIELD_LIST: 'VENDOR_API_FIELD_LIST',
	VENDOR_API_FIELD_LIST_SUCCESS: 'VENDOR_API_FIELD_LIST_SUCCESS',
	VENDOR_API_FIELD_LIST_FAIL: 'VENDOR_API_FIELD_LIST_FAIL',
	PACKAGE_VENDOR_API_FIELD_LIST_SUCCESS: 'PACKAGE_VENDOR_API_FIELD_LIST_SUCCESS',
	PACKAGE_VENDOR_API_FIELD_LIST_FAIL: 'PACKAGE_VENDOR_API_FIELD_LIST_FAIL',
	PACKAGE_VENDOR_API_FIELD_CREATE_SUCCESS: 'PACKAGE_VENDOR_API_FIELD_CREATE_SUCCESS',
	PACKAGE_VENDOR_API_FIELD_CREATE_FAIL: 'PACKAGE_VENDOR_API_FIELD_CREATE_FAIL',
	PACKAGE_VENDOR_API_FIELD_UPDATE_SUCCESS: 'PACKAGE_VENDOR_API_FIELD_UPDATE_SUCCESS',
	PACKAGE_VENDOR_API_FIELD_UPDATE_FAIL: 'PACKAGE_VENDOR_API_FIELD_UPDATE_FAIL',
	PACKAGE_VENDOR_API_FIELD_DELETE_SUCCESS: 'PACKAGE_VENDOR_API_FIELD_DELETE_SUCCESS',
	PACKAGE_VENDOR_API_FIELD_DELETE_FAIL: 'PACKAGE_VENDOR_API_FIELD_DELETE_FAIL',
	PACKAGE_VENDOR_API_FIELDS_MAPPING: 'PACKAGE_VENDOR_API_FIELDS_MAPPING',
	PACKAGE_VENDOR_API_FIELDS_MAPPING_SUCCESS: 'PACKAGE_VENDOR_API_FIELDS_MAPPING_SUCCESS',
	PACKAGE_VENDOR_API_FIELDS_MAPPING_FAIL: 'PACKAGE_VENDOR_API_FIELDS_MAPPING_FAIL',
	SNOWFLAKE_PACKAGE_VENDOR_API_FIELDS_UPDATE: 'SNOWFLAKE_PACKAGE_VENDOR_API_FIELDS_UPDATE',
	SNOWFLAKE_PACKAGE_VENDOR_API_FIELDS_UPDATE_SUCCESS: 'SNOWFLAKE_PACKAGE_VENDOR_API_FIELDS_UPDATE_SUCCESS',
	SNOWFLAKE_FIELD_TYPES_LIST_SUCCESS: 'SNOWFLAKE_FIELD_TYPES_LIST_SUCCESS',
	SNOWFLAKE_FIELD_TYPES_LIST_FAIL: 'SNOWFLAKE_FIELD_TYPES_LIST_FAIL',
};

export const CustomPackageVendorFieldManipulationsConstants = {
	FIELD_MANIPULATION_OPERATIONS_GET_SUCCESS: 'FIELD_MANIPULATION_OPERATIONS_GET_SUCCESS',
	FIELD_MANIPULATION_OPERATIONS_GET_FAIL: 'FIELD_MANIPULATION_OPERATIONS_GET_FAIL',
	EXECUTE_OPERATIONS_SUCCESS: 'EXECUTE_OPERATIONS_SUCCESS',
	EXECUTE_OPERATIONS_FAIL: 'EXECUTE_OPERATIONS_FAIL',
};

export const DragItemTypes = {
	SUMMARY_VENDOR_PRIORITY: 'SUMMARY_VENDOR_PRIORITY',
	DATA_EXCHANGE_FIELDS: 'DATA_EXCHANGE_FIELDS',
};
