export default {
	companies: 'companies',
	contacts: 'contacts',
	companies_dnb: 'companies-dnb',
	companies_list_dnb: 'companies-list-dnb',
	contacts_dnb: 'contacts-dnb',
	companies_hgdata: 'companies-hgdata',
	companies_insideview: 'companies-insideview',
	companies_zoominfo: 'companies-zoominfo',
	contacts_zoominfo: 'contacts-zoominfo',
	companies_bulk_zoominfo: 'companies-bulk-zoominfo',
	contacts_bulk_zoominfo: 'contacts-bulk-zoominfo',
	companies_everstring: 'companies-everstring',
	companies_clearbit: 'companies-clearbit',
	contacts_clearbit: 'contacts-clearbit',
	companies_cognism: 'companies-cognism',
	contacts_cognism: 'contacts-cognism',
	companies_snowflake: 'companies-snowflake',
	contacts_snowflake: 'contacts-snowflake',
};
