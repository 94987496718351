import { createApi } from '@reduxjs/toolkit/dist/query/react';
import baseQueryWithNProgress from 'app/utils/services/baseQueryWithNProgress';

import type { Permission, Role, Scope } from '../types/permissions';

export const permissionApi = createApi({
	reducerPath: 'permissionApi',
	baseQuery: baseQueryWithNProgress,
	tagTypes: ['Roles'],
	endpoints: builder => ({
		getScopes: builder.query<Scope[], void>({
			query: () => '/rl_permissions/scopes/',
			keepUnusedDataFor: 60 * 60 * 3, // 3 hour cache; rarely changed
		}),
		getRoles: builder.query<Role[], void>({
			query: () => '/rl_permissions/roles/',
			providesTags: [{ type: 'Roles' }],
		}),
		getRole: builder.query<Role, number>({
			query: id => `/rl_permissions/roles/${id}/`,
		}),
		createRole: builder.mutation<Role, Partial<Role>>({
			query: body => ({
				url: '/rl_permissions/roles/',
				method: 'POST',
				body,
			}),
			invalidatesTags: [{ type: 'Roles' }],
		}),
		editRole: builder.mutation<Role, Partial<Role> & { id: number }>({
			query: ({ id, ...body }) => ({
				url: `/rl_permissions/roles/${id}/`,
				method: 'PATCH',
				body,
			}),
			onQueryStarted({ id, ...patch }, { dispatch, queryFulfilled }) {
				const patchResul = dispatch(
					permissionApi.util.updateQueryData('getRole', id, draft => {
						Object.assign(draft, patch);
					})
				);
				queryFulfilled.catch(patchResul.undo);
			},
			invalidatesTags: [{ type: 'Roles' }],
		}),
		deleteRole: builder.mutation<unknown, number>({
			query: id => ({
				url: `/rl_permissions/roles/${id}/`,
				method: 'DELETE',
			}),
			onQueryStarted(id, { dispatch, queryFulfilled }) {
				const patchResult = dispatch(
					permissionApi.util.updateQueryData('getRoles', undefined, draft =>
						draft.filter(role => role.id !== id)
					)
				);
				queryFulfilled.catch(patchResult.undo);
			},
		}),
		getRolePermissions: builder.query<Permission[], number>({
			query: roleId => `/rl_permissions/${roleId}/permissions/`,
		}),
		editRolePermissions: builder.mutation<Permission[], { roleId: number; body: Partial<Permission>[] }>({
			query: ({ roleId, body }) => ({
				url: `/rl_permissions/${roleId}/permissions/`,
				method: 'POST',
				body,
			}),
			async onQueryStarted({ roleId }, { dispatch, queryFulfilled }) {
				try {
					const { data } = await queryFulfilled;
					dispatch(
						permissionApi.util.updateQueryData('getRolePermissions', roleId, draft => {
							data.forEach(permission => {
								const index = draft.findIndex(p => p.id === permission.id);
								if (index !== -1) {
									draft[index] = permission;
								} else {
									draft.push(permission);
								}
							});
						})
					);
				} catch {
					/* empty */
				}
			},
		}),
		deleteRolePermissions: builder.mutation<unknown, { roleId: number; ids: number[] }>({
			query: ({ roleId, ids }) => ({
				url: `/rl_permissions/${roleId}/permissions/`,
				method: 'DELETE',
				body: { ids },
			}),
			onQueryStarted({ roleId, ids }, { dispatch, queryFulfilled }) {
				const patchResult = dispatch(
					permissionApi.util.updateQueryData('getRolePermissions', roleId, draft =>
						draft.filter(role => !ids.includes(role.id))
					)
				);
				queryFulfilled.catch(patchResult.undo);
			},
		}),
	}),
});

export const {
	useGetScopesQuery,
	useGetRolesQuery,
	useGetRoleQuery,
	useCreateRoleMutation,
	useEditRoleMutation,
	useDeleteRoleMutation,
	useGetRolePermissionsQuery,
	useEditRolePermissionsMutation,
	useDeleteRolePermissionsMutation,
} = permissionApi;
