import { createAction, createSlice, isAnyOf } from '@reduxjs/toolkit';
import { LOCATION_CHANGE } from 'redux-first-history';

import { taskApi } from './task';

type TasksState = {
	errors: Record<string, string[]>;
	locked: boolean;
	updating: boolean;
};

// TMP SOLUTION!!! for new stack use only data from mutation hook
const tasksSlice = createSlice({
	name: 'tasks',
	initialState: { errors: {}, locked: false, updating: false } as TasksState,
	reducers: {},
	extraReducers: builder => {
		builder.addMatcher(isAnyOf(createAction(LOCATION_CHANGE)), state => {
			state.errors = {};
			state.locked = false;
			state.updating = false;
		});
		builder.addMatcher(taskApi.endpoints.createTask.matchPending, state => {
			state.locked = true;
		});
		builder.addMatcher(taskApi.endpoints.createTask.matchFulfilled, state => {
			state.errors = {};
			state.locked = false;
		});
		builder.addMatcher(taskApi.endpoints.createTask.matchRejected, (state, { payload }) => {
			state.errors = (payload?.data as Record<string, string[]>) || {};
			state.locked = false;
		});
		builder.addMatcher(taskApi.endpoints.editTask.matchPending, state => {
			state.updating = true;
		});
		builder.addMatcher(taskApi.endpoints.editTask.matchFulfilled, state => {
			state.updating = false;
		});
		builder.addMatcher(taskApi.endpoints.editTask.matchRejected, state => {
			state.updating = false;
		});
	},
});

export default tasksSlice.reducer;
