import ReactDOM from 'react-dom';

import RingLeadLoadable from '../RingLeadLoadable/RingLeadLoadable';

const getUserConfirmation = (message: string, callback: (a: boolean) => void) => {
	const div = document.createElement('div');
	div.id = 'confirmLeave';
	document.body.appendChild(div);

	const callBack = (ok: boolean) => {
		div.parentNode?.removeChild(div);
		callback(ok);
	};

	const UserConfirmation = RingLeadLoadable({
		loader: () => import('./UserConfirmation'),
	});

	ReactDOM.render(<UserConfirmation message={message} callback={callBack} />, div);
};

export default getUserConfirmation;
