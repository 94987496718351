export const ListImportDiscoveryType = 'discovery';
export const ListImportEventsType = 'events';
export const ListImportSource = [
	{
		label: 'Discover',
		value: ListImportDiscoveryType,
	},
	{
		label: 'Events',
		value: ListImportEventsType,
	},
	{
		label: 'List',
		value: 'form',
	},
];

export const DiscoveryInclusionExclusionFields = {
	company_name: 'CompanyName',
	company_linkedin_url: 'CompanyLinkedinUrl',
	duns: 'DUNS',
	hq_city: 'HQCity',
	hq_country_code: 'HQCountryCode',
	hq_state_code: 'HQStateCode',
	hq_street: 'HQStreet1',
	website: 'Website',
};

export const DiscoveryIEImportTypes = {
	csv: 'csv',
	salesforce: 'sf',
};

export const EXCLUSION_LIST_TYPE = 'exclusion';
export const INCLUSION_LIST_TYPE = 'inclusion';

export const IEListTypes = {
	STATIC: 'static',
	DYNAMIC: 'dynamic',
};

export const RECORDS_PER_PAGE = 25;
