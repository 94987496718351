import type { User } from 'app/auth/types/user';

export const setAdaFields = (user: User) => {
	window.adaEmbed?.setMetaFields({
		Email: user.email,
		First_Name: user.first_name,
		Last_Name: user.last_name,
		Is_Admin: user.is_admin,
		'Organization ID': user.organization.id,
		'Organization Name': user.organization.name,
		Available_Products: user.available_products.join(','),
		Mobile_number: user.mobile_number,
	});
};
