import { Route, Switch } from 'react-router';
import map from 'lodash-es/map';
import RingLeadLoadable from 'ringlead_utils/components/RingLeadLoadable/RingLeadLoadable';
import { HELP_CENTER_PAGES } from 'ringlead_utils/HelpCenterConstants';
import SecureRoute from 'ringlead_utils/routes/SecureRoute';

const UserInfo = RingLeadLoadable({
	loader: () => import('../../admin/components/users/profile/UserInfo'),
});

const UserSettings = RingLeadLoadable({
	loader: () => import('../../admin/components/users/profile/UserSettings'),
});

const HelpPage = RingLeadLoadable({
	loader: () => import('ringlead_utils/components/HelpPage'),
});

const AuditLogList = RingLeadLoadable({
	loader: () => import('../../audit_logs/components/audit_logs/AuditLogList'),
});

const AuditLogReports = RingLeadLoadable({
	loader: () => import('../../audit_logs/components/reports/AuditLogReports'),
});

const Dashboard = RingLeadLoadable({
	loader: () => import('../../dop/components/dashboard/overview/OverviewDashboard'),
});

const ReportCard = RingLeadLoadable({
	loader: () => import('../../dop/components/dashboard/report_card/ReportCard'),
});

const ListScheduledTasks = RingLeadLoadable({
	loader: () => import('../../settings/components/scheduling/ListScheduledTasks'),
});

const SystemDictionaries = RingLeadLoadable({
	loader: () => import('../../settings/components/system_dictionaries/SystemDictionaries'),
});

const ReplaceValuesList = RingLeadLoadable({
	loader: () => import('../../settings/components/replace_values/ReplaceValuesList'),
});

const OAuthManagement = RingLeadLoadable({
	loader: () => import('../../admin/components/oauth/Management'),
});

const Settings = RingLeadLoadable({
	loader: () => import('../../notification/components/Settings'),
});

const MyTasks = RingLeadLoadable({
	loader: () => import('../../dop/components/dashboard/tasks/MyTasks'),
});

const APIAccess = RingLeadLoadable({
	loader: () => import('../../admin/components/api_access/APIAccess'),
});

const ConnectionsRoutes = RingLeadLoadable({
	loader: () => import('./ConnectionsRoutes'),
});

const DiscoverRelatedRoutes = RingLeadLoadable({
	loader: () => import('./DiscoverRelatedRoutes'),
});

const DiscoveryRoutes = RingLeadLoadable({
	loader: () => import('./DiscoveryRoutes'),
});

const LicensingRoutes = RingLeadLoadable({
	loader: () => import('./LicensingRoutes'),
});

const PackageManagementRoutes = RingLeadLoadable({
	loader: () => import('./PackageManagementRoutes'),
});

const RoutingRoutes = RingLeadLoadable({
	loader: () => import('./RoutingRoutes'),
});

const SegmentationRoutes = RingLeadLoadable({
	loader: () => import('./SegmentationRoutes'),
});

const ScoreRoutes = RingLeadLoadable({
	loader: () => import('./ScoreRoutes'),
});

const NormalizeRoutes = RingLeadLoadable({
	loader: () => import('./NormalizeRoutes'),
});

const TaskRoutes = RingLeadLoadable({
	loader: () => import('./TaskRoutes'),
});

const TemplateRoutes = RingLeadLoadable({
	loader: () => import('./TemplateRoutes'),
});

const UserRoutes = RingLeadLoadable({
	loader: () => import('./UserRoutes'),
});

const VendorKeys = RingLeadLoadable({
	loader: () => import('../../vendors/components/vendor_keys/list/VendorKeys'),
});

const NotFoundPage = RingLeadLoadable({
	loader: () => import('ringlead_utils/components/NotFoundPage'),
});

const readOrgPermission = 'read:organization';

export default (
	<Switch>
		<Route exact path="/" component={MyTasks} />
		<Route path="/activity_logs" component={AuditLogList} />
		<Route path="/activity_reports" component={AuditLogReports} />
		<SecureRoute
			path="/report-card"
			component={ReportCard}
			permissions={['read:field_analysis', 'read:deduplicate_task']}
		/>
		<Route path="/performance-dashboard" component={Dashboard} />
		<Route path="/user/settings" component={UserSettings} />
		<Route path="/user" component={UserInfo} />
		{map(HELP_CENTER_PAGES, (page, index) => (
			<Route key={index} path={page.url} component={HelpPage} />
		))}
		<SecureRoute
			path="/intelligent_forms"
			component={TaskRoutes}
			features={['INTELLIGENT_FORMS']}
			permissions={['read:intelligent_form']}
		/>
		<SecureRoute
			path="/deduplication"
			component={TaskRoutes}
			features={['SALESFORCE_DEDUPLICATION', 'MARKETO_DEDUPLICATION', 'ELOQUA_DEDUPLICATION']}
			permissions={['read:deduplicate_task']}
		/>
		<SecureRoute
			path="/enrichment"
			component={TaskRoutes}
			features={['SALESFORCE_ENRICHMENT', 'MARKETO_ENRICHMENT', 'ELOQUA_ENRICHMENT', 'PARDOT_ENRICHMENT']}
			permissions={['read:enrich_task']}
		/>
		<SecureRoute
			path="/normalization"
			component={TaskRoutes}
			features={[
				'SALESFORCE_NORMALIZATION',
				'MARKETO_NORMALIZATION',
				'ELOQUA_NORMALIZATION',
				'PARDOT_NORMALIZATION',
			]}
			permissions={['read:normalize/segment_task']}
		/>
		<SecureRoute
			path="/mass-delete"
			component={TaskRoutes}
			features={[
				'SALESFORCE_MASS_DELETE',
				'MARKETO_MASS_DELETE',
				'PARDOT_MASS_DELETE',
				'ELOQUA_MASS_DELETE',
				'DYNAMICS_MASS_DELETE',
			]}
			permissions={['read:mass_delete_task']}
		/>
		<SecureRoute
			path="/mass-update"
			component={TaskRoutes}
			features={[
				'SALESFORCE_MASS_UPDATE',
				'MARKETO_MASS_UPDATE',
				'PARDOT_MASS_UPDATE',
				'ELOQUA_MASS_UPDATE',
				'DYNAMICS_MASS_UPDATE',
			]}
			permissions={['read:mass_update_task']}
		/>
		<SecureRoute
			path="/list-import"
			component={TaskRoutes}
			features={['SALESFORCE_LIST_IMPORT', 'ELOQUA_LIST_IMPORT']}
			permissions={['read:list_import_task']}
		/>
		<SecureRoute
			path="/web-submission"
			component={TaskRoutes}
			features={['SALESFORCE_W2L', 'SF_ONLY_PREVENTION', 'W2L_MARKETO', 'ELOQUA_W2L', 'PARDOT_W2L']}
			permissions={['read:web_submission_task']}
		/>
		<SecureRoute
			path="/webapi"
			component={TaskRoutes}
			features={['ENRICHMENT_API']}
			permissions={['read:enrich_api_task']}
		/>
		<SecureRoute
			path="/unique-entry"
			component={TaskRoutes}
			features={['ENRICHMENT_API']}
			permissions={['read:unique_entry_task']}
		/>
		<SecureRoute path="/discovery" component={DiscoveryRoutes} features={['DISCOVERY']} />
		<SecureRoute path="/system-dictionaries" component={SystemDictionaries} permissions={[readOrgPermission]} />
		<SecureRoute path="/licensing" component={LicensingRoutes} isAdmin permissions={[readOrgPermission]} />
		<Route path="/connections" component={ConnectionsRoutes} />
		<SecureRoute path="/replace-values" component={ReplaceValuesList} permissions={['read:replace_dictionary']} />
		<Route path="/scheduling" component={ListScheduledTasks} />
		<SecureRoute path="/notifications" component={Settings} isAdmin permissions={[readOrgPermission]} />
		<SecureRoute path="/templates" component={TemplateRoutes} permissions={['read:template']} />
		<SecureRoute
			path="/user-management"
			component={UserRoutes}
			isAdmin
			permissions={[readOrgPermission, 'read:user', 'read:invitation', 'read:role']}
		/>
		<SecureRoute
			path="/auth-api-access"
			component={APIAccess}
			isAdmin
			features={['API_ACCESS']}
			permissions={[readOrgPermission, 'read:api_key']}
		/>
		<Route path="/auth-manage" component={OAuthManagement} />
		<SecureRoute
			path="/vendor-manage/vendor-keys"
			component={VendorKeys}
			isAdmin
			features={['DATA_EXCHANGE']}
			permissions={['read:vendor_auth']}
		/>
		<SecureRoute
			path="/segmentation"
			component={SegmentationRoutes}
			features={['SEGMENTATION']}
			permissions={['read:config']}
		/>
		<SecureRoute
			path="/discover-related"
			component={DiscoverRelatedRoutes}
			features={['DISCOVER_RELATED']}
			permissions={['read:config']}
		/>
		<SecureRoute path="/score" component={ScoreRoutes} features={['SCORE']} permissions={['read:model']} />
		<SecureRoute
			path="/normalize"
			component={NormalizeRoutes}
			features={['NORMALIZATION']}
			permissions={['read:template']}
		/>
		<SecureRoute path="/routing" component={RoutingRoutes} features={['ROUTING']} />
		<SecureRoute
			path="/package-management"
			component={PackageManagementRoutes}
			isAdmin
			features={['DATA_EXCHANGE']}
			permissions={['read:package']}
		/>
		<Route path="*" component={NotFoundPage} />
	</Switch>
);
