import { amplitudeLogEvent } from 'app/integrations/amplitude/amplitude';
import pickBy from 'lodash-es/pickBy';

import { NavigationConstants } from '../Constants';

const initialState = {
	open: true,
	navbarState: JSON.parse(localStorage.getItem('navbarState') || '{}'),
};

export default (state = initialState, action = {}) => {
	switch (action.type) {
		case NavigationConstants.COLLAPSE_MENU:
			amplitudeLogEvent('SidePanelColapsedExpanded', {
				sidePanelAction: state.open ? 'Collapsed' : 'Expanded',
				source: window.amplitudeSource,
				activity_group: 'Navigation',
			});
			return { ...state, open: !state.open };

		case NavigationConstants.COLLAPSE_MENU_ITEM: {
			const navbarState = state.navbarState[action.hash]
				? pickBy(state.navbarState, (_v, k) => k !== action.hash)
				: { ...state.navbarState, [action.hash]: 1 };

			localStorage.setItem('navbarState', JSON.stringify(navbarState));

			return {
				...state,
				navbarState,
			};
		}

		default:
			return state;
	}
};
