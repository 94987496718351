import includes from 'lodash-es/includes';
import pickBy from 'lodash-es/pickBy';
import unionBy from 'lodash-es/unionBy';
import { LOCATION_CHANGE } from 'redux-first-history';

import { ConnectionFieldsConstants } from '../Constants';

const initialState = {
	fields: [],
	fieldsFetched: {},
};

export default (state = initialState, action = {}) => {
	switch (action.type) {
		case LOCATION_CHANGE:
			if (
				(includes(action.payload.location.pathname, 'resolution') ||
					(includes(action.payload.location.pathname, '/routing/workflows/') &&
						action.payload.location.pathname !== '/routing/workflows/')) &&
				!includes(action.payload.location.pathname, 'auth')
			) {
				return state;
			}

			return initialState;

		case ConnectionFieldsConstants.FIELDS_LIST:
			return {
				...state,
				fieldsFetched: { ...state.fieldsFetched, [action.table]: false },
			};

		case ConnectionFieldsConstants.FIELDS_LIST_SUCCESS:
			return {
				...state,
				fields: unionBy(action.data, state.fields, 'id'),
				fieldsFetched: { ...state.fieldsFetched, [action.table]: true },
			};

		case ConnectionFieldsConstants.FIELDS_LIST_FAIL:
		case ConnectionFieldsConstants.FIELDS_LIST_CLEAR:
			return {
				...state,
				fields: state.fields.length === 0 ? state.fields : [],
				fieldsFetched: pickBy(state.fieldsFetched, (_v, k) => action.table !== k),
			};

		default:
			return state;
	}
};
