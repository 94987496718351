import { createApi } from '@reduxjs/toolkit/dist/query/react';
import pusher from 'app/integrations/pusher/pusher';
import type { Connection } from 'app/settings/types/connectionTypes';
import baseQueryWithNProgress from 'app/utils/services/baseQueryWithNProgress';

import { resolveAPIUrlByTaskType } from '../helpers/taskHelper';
import type { Task, TaskEnrichField, TaskType } from '../types/taskTypes';

export const taskApi = createApi({
	reducerPath: 'taskApi',
	baseQuery: baseQueryWithNProgress,
	endpoints: builder => ({
		getTasks: builder.query<Task[], TaskType>({
			query: taskType => `/${resolveAPIUrlByTaskType(taskType)}/`,
			keepUnusedDataFor: 0, // TODO: invalidate on connection delete
			async onCacheEntryAdded(_, { updateCachedData, cacheDataLoaded, cacheEntryRemoved }) {
				const listener = ({
					id,
					task_type: taskType,
					snapshot_id: snapshotId,
					...patch
				}: Task & { snapshot_id?: number }) => {
					updateCachedData(draft => {
						draft.forEach(task => {
							if (task.id === id && task.task_type === taskType) {
								const updatedTask = { ...patch };
								if ('stats' in updatedTask && 'stats' in task && task.stats) {
									updatedTask.stats =
										task.stats.id === snapshotId
											? { ...task.stats, ...updatedTask.stats }
											: task.stats;
								}
								Object.assign(task, updatedTask);
							}
						});
					});
				};
				const listenerConnection = ({ id, ...patch }: Connection) => {
					updateCachedData(draft => {
						draft.forEach(task => {
							if ('connection' in task && task.connection?.id === id) {
								Object.assign(task, { connection: { ...task.connection, ...patch } });
							}
						});
					});
				};

				try {
					await cacheDataLoaded;

					pusher.bind('task_status_update', listener);
					pusher.bind('connection_status_update', listenerConnection);
				} catch {
					/* empty */
				}
				await cacheEntryRemoved;
				pusher.unbind('task_status_update', listener);
				pusher.unbind('connection_status_update', listenerConnection);
			},
		}),
		getTask: builder.query<Task, { taskType: TaskType; id: number }>({
			query: ({ taskType, id }) => `/${resolveAPIUrlByTaskType(taskType)}/${id}/`,
			async onCacheEntryAdded(_, { updateCachedData, cacheDataLoaded, cacheEntryRemoved }) {
				const listener = ({
					id,
					task_type: taskType,
					snapshot_id: snapshotId,
					...patch
				}: Task & { snapshot_id?: number }) => {
					updateCachedData(draft => {
						if (draft.id === id && draft.task_type === taskType) {
							const updatedTask = { ...patch };
							if ('stats' in updatedTask && 'stats' in draft && draft.stats) {
								updatedTask.stats =
									draft.stats.id === snapshotId
										? { ...draft.stats, ...updatedTask.stats }
										: draft.stats;
							}
							Object.assign(draft, updatedTask);
						}
					});
				};
				const listenerConnection = ({ id, ...patch }: Connection) => {
					updateCachedData(draft => {
						if ('connection' in draft && draft.connection?.id === id) {
							Object.assign(draft, { connection: { ...draft.connection, ...patch } });
						}
					});
				};

				try {
					await cacheDataLoaded;

					pusher.bind('task_status_update', listener);
					pusher.bind('connection_status_update', listenerConnection);
				} catch {
					/* empty */
				}
				await cacheEntryRemoved;
				pusher.unbind('task_status_update', listener);
				pusher.unbind('connection_status_update', listenerConnection);
			},
		}),
		createTask: builder.mutation<
			Task,
			{
				taskType: TaskType;
				task: Partial<Task> | FormData;
				template?: string;
				uniqueId?: string;
				// tmp; remove after whole migration to use hook
				callback?: (data: Task) => void;
			}
		>({
			query: ({ taskType, task, template, uniqueId }) => ({
				url: `/${resolveAPIUrlByTaskType(taskType)}/`,
				headers: uniqueId ? { 'X-Progress-ID': uniqueId } : undefined,
				method: 'POST',
				body: task,
				params: template ? { template } : undefined,
				formData: typeof task === 'object' && task instanceof FormData,
			}),
			async onQueryStarted({ taskType, callback }, { dispatch, queryFulfilled }) {
				try {
					const { data } = await queryFulfilled;
					dispatch(
						taskApi.util.updateQueryData('getTasks', taskType, draft => {
							draft.push(data);
						})
					);
					callback?.(data);
				} catch {
					// do nothing
				}
			},
		}),
		editTasks: builder.mutation<Task[], (Partial<Task> & { id: number; task_type: TaskType })[]>({
			query: body => ({
				url: `/${resolveAPIUrlByTaskType(body[0].task_type)}/`,
				method: 'PATCH',
				body,
			}),
			onQueryStarted(body, { dispatch, queryFulfilled }) {
				const patchResult = dispatch(
					taskApi.util.updateQueryData('getTasks', body[0].task_type, draft => {
						draft.forEach(task => {
							const updatedTask = body.find(({ id }) => id === task.id);
							if (updatedTask) {
								Object.assign(task, updatedTask);
							}
						});
					})
				);
				queryFulfilled.catch(patchResult.undo);
			},
		}),
		editTask: builder.mutation<Task, Partial<Task> & { id: number; task_type: TaskType }>({
			query: ({ id, task_type: taskType, ...body }) => ({
				url: `/${resolveAPIUrlByTaskType(taskType)}/${id}/`,
				method: 'PATCH',
				body,
			}),
			async onQueryStarted({ task_type: taskType }, { dispatch, queryFulfilled }) {
				try {
					const { data } = await queryFulfilled;
					dispatch(
						taskApi.util.updateQueryData('getTasks', taskType, draft => {
							draft.forEach(t => {
								if (t.id === data.id) {
									Object.assign(t, data);
								}
							});
						})
					);
					dispatch(
						taskApi.util.updateQueryData('getTask', { taskType, id: data.id }, draft => {
							Object.assign(draft, data);
						})
					);
				} catch {
					// do nothing
				}
			},
		}),
		taskAction: builder.mutation<
			Task,
			{
				id: number;
				task_type: TaskType;
				action: string;
				body: object;
				// tmp; remove after whole migration to use hook
				callback?: (data: Task) => void;
			}
		>({
			query: ({ id, task_type: taskType, action, body }) => ({
				url: `/${resolveAPIUrlByTaskType(taskType)}/${id}/_${action}/`,
				method: 'POST',
				body,
			}),
			async onQueryStarted({ id, task_type: taskType, callback }, { dispatch, queryFulfilled }) {
				try {
					const { data } = await queryFulfilled;
					dispatch(
						taskApi.util.updateQueryData('getTasks', taskType, draft => {
							draft.forEach(t => {
								if (t.id === id) {
									Object.assign(t, data);
								}
							});
						})
					);
					dispatch(
						taskApi.util.updateQueryData('getTask', { taskType, id }, draft => {
							Object.assign(draft, data);
						})
					);
					callback?.(data);
				} catch {
					// do nothing
				}
			},
		}),
		editTaskOwner: builder.mutation<
			Task,
			{
				id: number;
				task_type: TaskType;
				owner_group_id: number | null;
				owner_user_id: number | null;
			}
		>({
			query: ({ id, task_type: taskType, ...body }) => ({
				url: `/${resolveAPIUrlByTaskType(taskType)}/${id}/owner/`,
				method: 'PATCH',
				body,
			}),
			async onQueryStarted({ task_type: taskType }, { dispatch, queryFulfilled }) {
				try {
					const { data } = await queryFulfilled;
					dispatch(
						taskApi.util.updateQueryData('getTasks', taskType, draft => {
							draft.forEach(t => {
								if (t.id === data.id) {
									Object.assign(t, data);
								}
							});
						})
					);
					dispatch(
						taskApi.util.updateQueryData('getTask', { taskType, id: data.id }, draft => {
							Object.assign(draft, data);
						})
					);
				} catch {
					// do nothing
				}
			},
		}),
		uploadTask: builder.mutation<Task, Partial<Task> & { id: number; task_type: TaskType; file: File }>({
			query: ({ id, task_type: taskType, file }) => {
				const body = new FormData();
				body.append('file', file);

				return {
					url: `/${resolveAPIUrlByTaskType(taskType)}/${id}/upload_file/`,
					headers: { 'X-Progress-ID': id.toString() },
					method: 'PATCH',
					body,
					formData: true,
				};
			},
			async onQueryStarted({ task_type: taskType }, { dispatch, queryFulfilled }) {
				try {
					const { data } = await queryFulfilled;
					dispatch(
						taskApi.util.updateQueryData('getTasks', taskType, draft => {
							draft.forEach(t => {
								if (t.id === data.id) {
									Object.assign(t, data);
								}
							});
						})
					);
					dispatch(
						taskApi.util.updateQueryData('getTask', { taskType, id: data.id }, draft => {
							Object.assign(draft, data);
						})
					);
				} catch {
					// do nothing
				}
			},
		}),
		deleteTask: builder.mutation<
			void,
			{
				taskType: TaskType;
				id: number; // tmp; remove after whole migration to use hook
				callback?: () => void;
			}
		>({
			query: ({ taskType, id }) => ({
				url: `/${resolveAPIUrlByTaskType(taskType)}/${id}/`,
				method: 'DELETE',
			}),
			async onQueryStarted({ taskType, id, callback }, { dispatch, queryFulfilled }) {
				try {
					await queryFulfilled;
					dispatch(
						taskApi.util.updateQueryData('getTasks', taskType, draft =>
							draft.filter(task => task.id !== id)
						)
					);
					callback?.();
				} catch {
					/* empty */
				}
			},
		}),
		getTaskEnrichFields: builder.query<TaskEnrichField[], { taskId: number; taskType: TaskType }>({
			query: ({ taskId, taskType }) => `/${resolveAPIUrlByTaskType(taskType)}/${taskId}/enrich_fields/`,
			keepUnusedDataFor: 0,
		}),
	}),
});

export const {
	useGetTasksQuery,
	useGetTaskQuery,
	useCreateTaskMutation,
	useEditTaskMutation,
	useTaskActionMutation,
	useUploadTaskMutation,
	useGetTaskEnrichFieldsQuery,
} = taskApi;
