import isEqual from 'lodash-es/isEqual';
import { LOCATION_CHANGE } from 'redux-first-history';

import { FormulaConstants } from '../Constants';

const initialState = {
	formulas: {},
	validationResult: { success: null, message: '' },
};

export default (state = initialState, action = {}) => {
	switch (action.type) {
		case LOCATION_CHANGE:
			return { ...initialState, formulas: state.formulas };

		case FormulaConstants.LOAD_FORMULAS_SUCCESS:
			return { ...state, formulas: action.data };

		case FormulaConstants.LOAD_FORMULAS_FAIL:
			return { ...state, formulas: [] };

		case FormulaConstants.VALIDATE_FORMULA_RESET:
			const defaultValidationResult = { success: null, message: '' };

			return {
				...state,
				validationResult: isEqual(state.validationResult, defaultValidationResult)
					? state.validationResult
					: defaultValidationResult,
			};

		case FormulaConstants.VALIDATE_FORMULA_SUCCESS:
			return {
				...state,
				validationResult: { success: true, message: action.data },
			};

		case FormulaConstants.VALIDATE_FORMULA_FAIL:
			return {
				...state,
				validationResult: { success: false, message: action.data },
			};

		default:
			return state;
	}
};
