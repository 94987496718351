// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const paramsSerializer = (params: Record<string, any>): string => {
	const searchParams = new URLSearchParams();

	Object.entries(params).forEach(([key, value]) => {
		if (Array.isArray(value)) {
			value.forEach(item => {
				searchParams.append(key, item);
			});
		} else {
			searchParams.append(key, value);
		}
	});

	return searchParams.toString();
};
