import { type MouseEvent, type ReactElement } from 'react';
import TextField, { type TextFieldProps } from '@mui/material/TextField';

function DatePickerField(props: TextFieldProps) {
	// eslint-disable-next-line @typescript-eslint/ban-ts-comment
	// @ts-ignore
	const endAdornment = props.ownerState.InputProps?.endAdornment;

	const onClick = (e: MouseEvent) => {
		if (endAdornment && typeof endAdornment === 'object' && !e.defaultPrevented) {
			(endAdornment as ReactElement).props.children.props.onClick(e);
		}
	};

	return (
		<TextField
			{...props}
			onBlur={() => {
				// empty
			}}
			onChange={() => {
				// empty
			}}
			onFocus={() => {
				// empty
			}}
			onClick={onClick}
		/>
	);
}

export default DatePickerField;
