import { TPL_TYPE_NR } from '../settings/constants/TemplateConstants';

export const NavigationConstants = {
	COLLAPSE_MENU: 'COLLAPSE_MENU',
	COLLAPSE_MENU_ITEM: 'COLLAPSE_MENU_ITEM',
};

export const ICON_TYPES = {
	ADMIN: 'admin',
	SUPER_ADMIN: 'super-admin',
	SETTINGS: 'settings',
};

export const PAGE_ACTIVITY_REPORTS = {
	label: 'Activity Reports',
	tool: 'Analyze - Activity Reports',
	href: '/activity_reports/',
	url: '/activity_reports/',
	help_page_text:
		'Create custom performance reports that quantify RingLead contributions to your data quality. Out of box reports for product and enrichment usage. ',
	help_center_url: 'https://help.zoominfo.com/s/article/Overview-of-RingLead-Activity-Reports',
};

export const PAGE_ADMINISTRATION = {
	label: 'Administration',
};

export const PAGE_USER_MANAGEMENT = {
	label: 'User Management',
	href: '/user-management/',
	url: '/user-management/',
};

export const PAGE_USER_ROLES = {
	label: 'Roles',
	href: `${PAGE_USER_MANAGEMENT.href}roles/`,
	url: `${PAGE_USER_MANAGEMENT.url}roles/`,
};

export const PAGE_USER_GROUPS = {
	label: 'Groups',
	href: `${PAGE_USER_MANAGEMENT.href}groups/`,
	url: `${PAGE_USER_MANAGEMENT.url}groups/`,
};

export const PAGE_SEGMENT = {
	label: 'Segment',
	tool: 'Transform - Segment',
	href: '/segmentation/',
	help_page_text: 'Define the rules for creating Segment values. ',
	help_center_url: 'https://help.zoominfo.com/s/article/Overview-of-RingLead-Segment',
};

export const PAGE_SCORE = {
	label: 'Score',
	tool: 'Transform - Score',
	href: '/score/',
	help_page_text: 'Define the model for generating Score values. ',
	help_center_url:
		'https://help.zoominfo.com/lightning/articles/help/How-to-Create-and-Operationalize-a-New-Score-Model',
};

export const PAGE_SETTINGS = {
	label: 'Settings',
};

export const PAGE_TEMPLATES = {
	label: 'Templates',
	href: '/templates/',
	url: '/templates/',
};

export const PAGE_TEMPLATES_NORMALIZATION = {
	label: 'Normalization',
	tool: 'Transform - Normalization',
	href: `${PAGE_TEMPLATES.href}${TPL_TYPE_NR}/`,
	url: `${PAGE_TEMPLATES.url}${TPL_TYPE_NR}/`,
};

export const PAGE_NORMALIZE = {
	label: 'Normalize',
	tool: 'Transform - Normalization',
	href: '/normalize/',
	url: '/normalize/',
	help_page_text: 'Define Normalization Rules. ',
	help_center_url: 'https://help.zoominfo.com/s/article/Overview-of-Normalization-Rules-in-RingLead',
};
