import { type ComponentType, lazy, Suspense } from 'react';

import Preloader from '../Preloader';

import RingLeadLoadableErrorBoundary from './RingLeadLoadableErrorBoundary';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function RingLeadLoadable<T extends ComponentType<any>>(opts: { loader: () => Promise<{ default: T }> }) {
	const Component = lazy(opts.loader);

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	return function RingLeadLoadableWrapper(props: any) {
		return (
			<RingLeadLoadableErrorBoundary>
				<Suspense fallback={<Preloader />}>
					<Component {...props} />
				</Suspense>
			</RingLeadLoadableErrorBoundary>
		);
	};
}

export default RingLeadLoadable;
