import type { Middleware } from '@reduxjs/toolkit';
import { isRejectedWithValue } from '@reduxjs/toolkit';
import { logout } from 'app/auth/services/authSlice';
import { enqueueSnackbar } from 'notistack';

const rtkQueryErrorMiddleware: Middleware =
	({ dispatch }) =>
	next =>
	action => {
		if (isRejectedWithValue(action)) {
			const status = action.payload.originalStatus || action.payload.status;

			switch (status) {
				case 400: // typically validation errors
					break;
				case 403: // typically forbidden errors
					enqueueSnackbar({
						key: '403',
						message: 'You do not have permission to perform this action.',
						variant: 'error',
					});
					break;
				case 502: // Bad Gateway
					enqueueSnackbar({
						key: '502',
						message: 'Sorry, but service is unavailable. Please, try later.',
						variant: 'error',
					});
					break;
				case 401:
					dispatch(logout());
					break;
				default:
					const err = [`URL: ${action.meta.baseQueryMeta?.request?.url || ''}`, `Status: ${status}`];
					if (action.payload.status !== 'PARSING_ERROR') {
						err.push(`Response: ${JSON.stringify(action.payload.data)}`);
					} else if (action.meta.baseQueryMeta?.response?.statusText) {
						err.push(`Status Text: ${action.meta.baseQueryMeta.response.statusText}`);
					}

					enqueueSnackbar({
						key: `${action.meta.baseQueryMeta?.request?.url || ''}_${action.payload.status}`,
						message: err.map(e => <p key={e}>{e}</p>),
						variant: 'error',
					});
					break;
			}
		}

		return next(action);
	};

export default rtkQueryErrorMiddleware;
