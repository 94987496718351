import concat from 'lodash-es/concat';
import filter from 'lodash-es/filter';
import map from 'lodash-es/map';
import { LOCATION_CHANGE } from 'redux-first-history';

import { SegmentationMappingConstants } from '../Constants';

const initialState = {
	mappings: [],
	fetching: false,
};

export default (state = initialState, action = {}) => {
	switch (action.type) {
		case LOCATION_CHANGE:
			return initialState;

		case SegmentationMappingConstants.SEGMENT_MAPPINGS_FETCHING:
			return { ...state, fetching: true };

		case SegmentationMappingConstants.SEGMENT_MAPPINGS_GET_SUCCESS:
			return { ...state, mappings: action.data, fetching: false };

		case SegmentationMappingConstants.SEGMENT_MAPPINGS_GET_FAIL:
			return { ...state, mappings: [], fetching: false };

		case SegmentationMappingConstants.SEGMENT_MAPPINGS_CREATE_SUCCESS:
		case SegmentationMappingConstants.SEGMENT_MAPPINGS_CREATE_FAIL:
			return {
				...state,
				mappings: concat(
					[],
					filter(state.mappings, mapping => !mapping.tmpId || mapping.tmpId !== action.tmpId),
					action.data
				),
			};

		case SegmentationMappingConstants.SEGMENT_MAPPINGS_UPDATE_SUCCESS:
			return {
				...state,
				mappings: map(state.mappings, fm => (fm.id === action.data.id ? { ...fm, ...action.data } : fm)),
			};

		case SegmentationMappingConstants.SEGMENT_MAPPINGS_DELETE_SUCCESS:
			return {
				...state,
				mappings: filter(state.mappings, fm => (fm.id || fm.tmpId) !== action.id),
			};

		case SegmentationMappingConstants.SEGMENT_MAPPINGS_UPDATE_FAIL:
		case SegmentationMappingConstants.SEGMENT_MAPPINGS_ROW_CREATE_FAIL:
		case SegmentationMappingConstants.SEGMENT_MAPPINGS_ROW_UPDATE_FAIL:
		case SegmentationMappingConstants.SEGMENT_MAPPINGS_DELETE_FAIL:
			return state;

		case SegmentationMappingConstants.SEGMENT_MAPPINGS_ROW_CREATE_SUCCESS:
			return {
				...state,
				mappings: map(state.mappings, mapping => {
					if (mapping.id !== action.data.config_mapping) {
						return mapping;
					}

					return action.data.seg_label.is_output
						? { ...mapping, [action.data.object_name]: { ...action.data } }
						: {
								...mapping,
								input_mappings: {
									...mapping.input_mappings,
									[action.data.seg_label.name]: {
										...mapping.input_mappings[action.data.seg_label.name],
										seg_label: action.data.seg_label.name,
										seg_label_id: action.data.seg_label.id,
										[action.data.object_name]: { ...action.data },
									},
								},
							};
				}),
			};

		case SegmentationMappingConstants.SEGMENT_MAPPINGS_ROW_UPDATE_SUCCESS:
			return {
				...state,
				mappings: map(state.mappings, mapping => {
					if (mapping.id !== action.data.config_mapping) {
						return mapping;
					}

					return action.data.seg_label.is_output
						? {
								...mapping,
								[action.data.object_name]: { ...mapping[action.data.object_name], ...action.data },
							}
						: {
								...mapping,
								input_mappings: {
									...mapping.input_mappings,
									[action.data.seg_label.name]: {
										...mapping.input_mappings[action.data.seg_label.name],
										seg_label: action.data.seg_label.name,
										seg_label_id: action.data.seg_label.id,
										[action.data.object_name]: { ...action.data },
									},
								},
							};
				}),
			};

		default:
			return state;
	}
};
