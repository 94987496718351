import { LOCATION_CHANGE } from 'redux-first-history';

import { CustomPackageVendorFieldManipulationsConstants } from '../Constants';

const initialState = {
	fieldManipulationOperations: [],
	outcomingData: null,
	stringOutcomingData: null,
};

export default (state = initialState, action = {}) => {
	switch (action.type) {
		case LOCATION_CHANGE:
			return initialState;

		case CustomPackageVendorFieldManipulationsConstants.FIELD_MANIPULATION_OPERATIONS_GET_SUCCESS:
			return { ...state, fieldManipulationOperations: action.data };

		case CustomPackageVendorFieldManipulationsConstants.FIELD_MANIPULATION_OPERATIONS_GET_FAIL:
			return { ...state, fieldManipulationOperations: [] };

		case CustomPackageVendorFieldManipulationsConstants.EXECUTE_OPERATIONS_SUCCESS:
			return {
				...state,
				outcomingData: action.data.outcoming_data,
				stringOutcomingData: action.data.string_outcoming_data,
			};

		case CustomPackageVendorFieldManipulationsConstants.EXECUTE_OPERATIONS_FAIL:
			return { ...state, outcomingData: null, stringOutcomingData: null };

		default:
			return state;
	}
};
